import React from "react";
import i18n from "../../i18next";
import { Link } from 'react-router-dom';
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import _ from "lodash";

const NewsSection = ({ newsArr = [] }) => (
  <Row className="newsSection justify-content-center mt-3 mt-sm-2 mt-md-4 mt-xl-5">
    <Col xs="12" sm="12" md="3" lg="3" xl="3">
      <h3 className="title mx-auto mb-3 my-md-auto text-center pb-2">{i18n.t("home.news")}</h3>
    </Col>
    <Col className="mt-md-4" xs="12" sm="11" md="9" lg="6" xl="6">
      <NewsList
        newsArr={newsArr}
      />
    </Col>
  </Row>
)

const NewsList = ({ newsArr = [] }) => (
  <ListGroup>
    {newsArr.map((item, index) => (
      <ListGroupItem key={index.toString()} className="newsItem d-flex">
        <Link className={`d-flex ${!_.get(item, "link") ? "disabled" : ""}`}
          id={_.get(item, "link")} to={_.get(item, "link", "#")}>
          <p className="m-0 pr-2">
            [{item.date.getFullYear()}.{_.padStart(item.date.getMonth() + 1, 2, 0)}]
          </p>
          <p className="m-0">{_.get(item, "title")}</p>
        </Link>
      </ListGroupItem>
    ))
    }
  </ListGroup>
)

export default NewsSection;