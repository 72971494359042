import firebase from "firebase/app";
import "firebase/functions"; // Required for side-effects

// initialize app
const config = {
  apiKey: "AIzaSyD-Cwpv5jlFjtdq6OkkJpIE9pgj7kqQHpM",
  authDomain: "gopomo-contactus.firebaseapp.com",
  databaseURL: "https://gopomo-contactus.firebaseio.com",
  projectId: "gopomo-contactus",
  storageBucket: "gopomo-contactus.appspot.com",
  messagingSenderId: "444150025395"
};
const contactUsApp = firebase.initializeApp(config, "contact-us");

const sendContactMessage =contactUsApp.functions().httpsCallable('sendContactMessage');

export default sendContactMessage;
