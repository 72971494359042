import React from "react";
import iPhoneXWhiteMockup from "../../../images/portfolio/iPhoneXWhiteModal.png";
import iPhoneXBlackMockup from "../../../images/portfolio/iPhoneXBlackModal.png";
import mobileDefaultMockup from "../../../images/portfolio/mobileDefaultModal.png";
import desktopMockup from "../../../images/portfolio/macModel.png";
import { Col, Button } from "reactstrap";

class DesktopView extends React.Component {
  static defaultProps = {
    screenshotUrls: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      scrollDirection: "down", /* down, up */
      needPauseAutoScroll: false, /* as user mouse enter, pause auto scrolling */
    };
  }

  /* mounted status, to determine whether to setState */
  mounted = false;
  /* for user scrolling record */
  userLastScrollTopAnchor = 0;
  /* reference to AutoScroll setInterval */
  autoScrollFunctionRef = null;
  /* reference for next Screenshot container */
  screenshotContainerRef = React.createRef();
  /* reference for next Screenshot container */
  iconContainerRef = React.createRef();

  componentDidMount() {
    this._mounted = true;
    this.onDesktopModalAutoScrolling();
  }

  componentWillReceiveProps() {
    this.onShowSpreadBubbles();
  }

  componentWillUnmount() {
    if (this.autoScrollFunctionRef) {
      clearInterval(this.autoScrollFunctionRef);
      this.autoScrollFunctionRef = null;
    }
    this._mounted = false;
  }

  onShowSpreadBubbles = () => {
    const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const iconContainerCurr = this.iconContainerRef.current;
    if (iconContainerCurr) {
      const nextButton = iconContainerCurr.querySelector("button.nextButton");
      if (iconContainerCurr.getBoundingClientRect().top < viewPortHeight / 2 && nextButton) {
        /* if nextButton in the middle of viewport  */
        nextButton.classList.add("showBubbles");
      } else {
        nextButton.classList.remove("showBubbles");
      }
    }
  }

  onDesktopModalAutoScrolling = () => {
    const desktopScrollController = document.getElementById("desktopScroll");
    /* set scroll position */
    this.autoScrollFunctionRef = setInterval(() => {
      if (this._mounted && !this.state.needPauseAutoScroll) {
        let positionScrollTop = desktopScrollController.scrollTop;

        /* condition for scroll offset */
        if (desktopScrollController.scrollTop === 0) {
          /* on top of scroll, need to scroll down */
          positionScrollTop++;
          /* set state prevent glitch for continuously scrolling down */
          this.setState({ scrollDirection: "down" });
        } else if (desktopScrollController.scrollHeight === desktopScrollController.offsetHeight + desktopScrollController.scrollTop) {
          /* on bottom of scroll, trigger scrolling up */
          positionScrollTop--;
          /* set state prevent glitch for continuously scroll up */
          this.setState({ scrollDirection: "up" });
        } else {
          /* in the middle of scroll */
          if (this.state.scrollDirection === 'up') {
            positionScrollTop--;
          } else {
            positionScrollTop++;
          }
        }
        /* set scroll position */
        desktopScrollController.scrollTop = positionScrollTop;
      }
    }, 50);
  }

  onUserScroll = () => {
    const desktopScrollController = document.getElementById("desktopScroll");
    /* user scrolling statue */
    if (this.state.needPauseAutoScroll && this._mounted) {
      if (this.userLastScrollTopAnchor < desktopScrollController.scrollTop) {
        this.setState({ scrollDirection: "down" });
      } else {
        this.setState({ scrollDirection: "up" });
      }
      this.userLastScrollTopAnchor = desktopScrollController.scrollTop;
    }
  }

  changeNextScreenshot = (event) => {
    event.preventDefault();
    const aniDurMillisec = 500;
    /* reference to <ul> tag */
    const screenshotContainer = this.screenshotContainerRef.current;

    /* setup transition required style */
    screenshotContainer.style.transition = `all ${aniDurMillisec}ms ease-out`;
    /* translateX for current screenshot width */
    const transiformValue = `translateX(-${screenshotContainer.getBoundingClientRect().width}px)`;
    screenshotContainer.style.transform = transiformValue;
    /* second li setup disply block for showing */
    let secondTargetLi = screenshotContainer.querySelector("li:nth-child(2)");
    secondTargetLi.style.display = "block";

    /* set timeout after transition finished (set initial transition state) */
    setTimeout(() => {
      screenshotContainer.style.transition = `unset`;
      screenshotContainer.style.transform = `translateX(0px)`;

      /* after transition, move first li on back of li-list */
      const appendTargetLi = screenshotContainer.querySelector("li:first-child");
      screenshotContainer.appendChild(appendTargetLi);
      /* make other li `display: none`, NOTE: prevent height glitch */
      const hideLiArr = screenshotContainer.querySelectorAll("li:not(:first-child)");
      hideLiArr.forEach(liTarget => {
        liTarget.style.display = "none";
      });

      /* always make sure first one display
      NOTE: prevent glitch on Onclick fire too faster evnet */
      screenshotContainer.querySelector("li:first-child").style.display = "block";
    }, aniDurMillisec)
  }

  render() {
    return (
      <Col className="desktopView" xs="12" sm="12" md="10" lg="10" xl="9">
        {(this.props.screenshotUrls.length >= 2) ?
          <div className="d-flex justify-content-center my-3" ref={this.iconContainerRef}>
            <Button outline color="secondary" className="nextButton d-flex justify-content-center p-0"
              onClick={(event) => this.changeNextScreenshot(event)}>
              <div className="arrowSVG">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 30 80" xml="preserve">
                  <polyline fill="none" stroke="#6c757d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" points="0.375,0.375 45.63,38.087 0.375,75.8 " />
                </svg>
              </div>
            </Button>
          </div>
          :
          <div className="emptySpacing my-3" />
        }
        <div className="screen">
          <div className="filterScreenshot"
            id={"desktopScroll"}
            onScroll={() => this.onUserScroll()}
            onMouseEnter={() => this.setState({ needPauseAutoScroll: true })}
            onMouseLeave={() => this.setState({ needPauseAutoScroll: false })}
          >
            <ul className="p-0" ref={this.screenshotContainerRef}>
              {(this.props.screenshotUrls).map((url, index) => (
                <li key={(index.toString())} className="w-100">
                  <img src={url} className="screenshot img-fluid w-100" alt="screenshot" />
                </li>
              ))}
            </ul>
          </div>
          <img src={desktopMockup} className="desktop img-fluid" alt="decktopScreenhot" />
        </div>
      </Col>
    )
  }
}

const MobileiXWhiteView = ({ screenshotUrl = "" }) => (
  <Col className="mobileiXView" xs="12" sm="6" md="6" lg="3" xl="3">
    <div className="screen d-flex justify-content-center">
      <div className="filterScreenshot">
        <img src={screenshotUrl} className="screenshot" alt="mobileScreenshot" />
      </div>
      <img src={iPhoneXWhiteMockup} className="iPhone" alt="iPhoneModal" />
    </div>
  </Col>
)

class MobileiXBlackView extends React.Component {

  static defaultProps = {
    screenshotUrls: "",
  }

  /* reference for next Screenshot container */
  screenshotContainerRef = React.createRef();
  /* reference for next Screenshot container */
  iconContainerRef = React.createRef();

  componentWillReceiveProps() {
    this.onShowSpreadBubbles();
  }

  onShowSpreadBubbles = () => {
    const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const iconContainerCurr = this.iconContainerRef.current;
    if (iconContainerCurr) {
      const nextButton = iconContainerCurr.querySelector("button.nextButton");
      if (iconContainerCurr.getBoundingClientRect().top < viewPortHeight / 2 && nextButton) {
        /* if nextButton in the middle of viewport  */
        nextButton.classList.add("showBubbles");
      } else {
        nextButton.classList.remove("showBubbles");
      }
    }
  }

  changeNextScreenshot = (event) => {
    event.preventDefault();
    const aniDurMillisec = 500;
    /* reference to <ul> tag */
    const screenshotContainer = this.screenshotContainerRef.current;

    /* setup transition required style */
    screenshotContainer.style.transition = `all ${aniDurMillisec}ms ease-out`;
    /* translateX for current screenshot width */
    const transiformValue = `translateX(-${screenshotContainer.getBoundingClientRect().width}px)`;
    screenshotContainer.style.transform = transiformValue;
    /* second li setup disply block for showing */
    let secondTargetLi = screenshotContainer.querySelector("li:nth-child(2)");
    secondTargetLi.style.display = "block";

    /* set timeout after transition finished (set initial transition state) */
    setTimeout(() => {
      screenshotContainer.style.transition = `unset`;
      screenshotContainer.style.transform = `translateX(0px)`;

      /* after transition, move first li on back of li-list */
      const appendTargetLi = screenshotContainer.querySelector("li:first-child");
      screenshotContainer.appendChild(appendTargetLi);
      /* make other li `display: none`, NOTE: prevent other screenshots overlay glitch */
      const hideLiArr = screenshotContainer.querySelectorAll("li:not(:first-child)");
      hideLiArr.forEach(liTarget => { liTarget.style.display = "none"; });
      /* always make sure first one display
      NOTE: prevent glitch on multi-fireOnclick evnet */
      screenshotContainer.querySelector("li:first-child").style.display = "block";
    }, aniDurMillisec)
  }

  render() {
    return (
      <Col className="mobileiXView" xs="12" sm="6" md="6" lg="3" xl="3" >
        {(this.props.screenshotUrls.length >= 2) ?
          <div className="d-flex justify-content-center my-3" ref={this.iconContainerRef}>
            <Button outline color="secondary" className="nextButton d-flex justify-content-center p-0"
              onClick={(event) => this.changeNextScreenshot(event)}>
              <div className="arrowSVG">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 30 80" xml="preserve">
                  <polyline fill="none" stroke="#6c757d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" points="0.375,0.375 45.63,38.087 0.375,75.8 " />
                </svg>
              </div>
            </Button>
          </div>
          :
          <div className="emptySpacing my-3" />
        }
        <div className="screen d-flex justify-content-center">
          <div className="filterScreenshot">
            <ul className="p-0" ref={this.screenshotContainerRef}>
              {this.props.screenshotUrls.map((url, index) => (
                <li key={index.toString()}>
                  <img src={url} className="screenshot" alt="mobileScreenshot" />
                </li>
              ))}
            </ul>
          </div>
          <img src={iPhoneXBlackMockup} className="iPhone" alt="iPhoneModal" />
        </div>
      </Col>
    )
  }
}
// const MobileiXBlackView = ({ screenshotUrl = "" }) => (
//   <Col className="mobileiXView" xs="12" sm="6" md="6" lg="3" xl="3">
//     <div className="screen d-flex justify-content-center">
//       <div className="filterScreenshot">
//         <img src={screenshotUrl} className="screenshot" alt="mobileScreenshot" />
//       </div>
//       <img src={iPhoneXBlackMockup} className="iPhone" alt="iPhoneModal" />
//     </div>
//   </Col>
// )

const MobileBlackView = ({ screenshotUrl = "" }) => (
  <Col className="mobileDefaultView" xs="12" sm="6" md="6" lg="3" xl="3">
    <div className="screen d-flex justify-content-center">
      <div className="filterScreenshot">
        <img src={screenshotUrl} className="screenshot" alt="mobileScreenshot" />
      </div>
      <img src={mobileDefaultMockup} className="mobileDefault image-fluid" alt="mobileDefaultMockup" />
    </div>
  </Col>
)

export { DesktopView, MobileiXWhiteView, MobileiXBlackView, MobileBlackView };