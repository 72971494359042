import React from "react";
import { Row, Col } from "reactstrap";
import gopomoTextUrl from "../../images/gopomoText.svg";
import gopomo_G from "../../images/contact/G.svg";
import gopomo_O1 from "../../images/contact/O1.svg";
import gopomo_P from "../../images/contact/P.svg";
import gopomo_O2 from "../../images/contact/O2.svg";
import gopomo_M from "../../images/contact/M.svg";
import gopomo_O3 from "../../images/contact/O3.svg";

const ContactSection = ({ title = "", description = "", contactThroughApp = "" }) => (
  <Row className="contactSection text-center flex-column align-items-center justify-content-center">
    <div className="titleBorder" />
    <Col xs={{ size: 12 }} md={{ size: 6 }}>
      <h2>{title}</h2>
    </Col>
    <Col xs={{ size: 12 }} sm={{ size: 8 }}>
      <p>{description}</p>
      <p>{contactThroughApp}<br />
        <a className="socialLink" href="https://line.me/R/ti/p/%40crl3404r" target={"_blank"}>Line@</a>,&nbsp;&nbsp;
        <a className="socialLink" href="https://www.facebook.com/GopomoInc/" target={"_blank"}>Facebook.</a></p>
    </Col>
  </Row>
);


class LogoBanner extends React.Component {
  static defaultProps = {
    windowScrollY: 0
  }

  /* refernce for show brick animation */
  bricksWrapperRef = React.createRef();

  componentDidMount() {
    /* use componentDidMount for initailization */
    this.onShowBricks();
  }

  componentWillReceiveProps() {
    /* use componentWillReceiveProps from parent scrolling */
    this.onShowBricks();
  }

  onShowBricks = () => {
    const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (this.bricksWrapperRef) {
      const bricksWrapperRefCurr = this.bricksWrapperRef.current;
      if (bricksWrapperRefCurr.getBoundingClientRect().top < viewPortHeight - bricksWrapperRefCurr.getBoundingClientRect().height * 2) {
        const bricksArr = bricksWrapperRefCurr.querySelectorAll("span");
        bricksArr.forEach(brick => { brick.classList.add("brickFadeIn"); });
      }
    }
  }

  render() {
    return (
      <Row className="logoContainer text-center">
        <Col xs={{ size: 12 }} >
          <div className="logoCharContainer d-flex mx-auto justify-content-around">
            <img src={gopomo_G} className="charImg logoRollingIn img-fluid" alt="G" />
            <img src={gopomo_O1} className="charImg logoRollingIn img-fluid" alt="O" />
            <img src={gopomo_P} className="charImg logoRollingIn img-fluid" alt="P" />
            <img src={gopomo_O2} className="charImg logoRollingIn img-fluid" alt="O" />
            <img src={gopomo_M} className="charImg logoRollingIn img-fluid" alt="M" />
            <img src={gopomo_O3} className="charImg logoRollingIn img-fluid" alt="O" />
          </div>
        </Col>
        <Col xs={{ size: 12 }} className="d-none">
          <img src={gopomoTextUrl} className="img-fluid" alt="Gopomo" />
          <div className="bricks py-2 d-flex justify-content-center" ref={this.bricksWrapperRef}>
            <span /><span /><span /><span /><span /><span />
          </div>
        </Col>
      </Row>
    )
  }
}

export { ContactSection, LogoBanner };