import React from "react";
import i18n from "../../i18next";
import { Button, Col, Form, FormGroup, Label, Input, Container } from 'reactstrap';
import sendImageUrl from "../../images/contact/sendIcon.svg";

import sendContactMessage from "../../api/contactUs";


export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      company: "",
      phone: "",
      message: ""
    };
  }

  handleChange = (event) => {
    const key = event.target.id;
    this.setState({ [key]: event.target.value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let data = this.state;
    // api key 
    data['apikey'] = 'TBziQn6PcV8Hg8f213zOt7SLuw07BV3n';
    try {
      let result = await sendContactMessage(data);
      if (result.data.success) {
        this.setState({
          name: "",
          company: "",
          email: "",
          phone: "",
          message: ""
        });
        alert("📨 Thanks for being awesome! 😃");
      } else if (result.data.errors) {
        // TODO: handle form validation error
        // result.data.errors = {
        //   name: ['This field is required.'],
        // };
        alert(`Some of the form data is not valid. Please try again.`);
      } else {
        console.warn('Unknown stiuation.', result);
      }
    } catch (error) {
      // var code = error.code;
      // var message = error.message;
      // var details = error.details;
      // see: https://firebase.google.com/docs/functions/callable
      console.warn('Failt to send message.', error);
      alert(`${error.message} Please contact directly with dev@gopomo.com`);
    }
  }

  render() {
    return (
      <Form className="borderForm mt-2 mt-sm-3 mt-md-4 mt-lg-5 p-1 p-sm-3 p-md-4 px-lg-5"
        onSubmit={this.handleSubmit}>
        <Container>
          <FormGroup row className="justify-content-around justify-content-sm-center justify-content-xl-center">
            <Label xs={12} sm={5} md={4} lg={5} xl={{ size: 3, offset: 3 }} for="name" className="">
              {i18n.t("contact.form.name")}<sup>*</sup>
            </Label>
            <Col xs={10} sm={5} md={5} lg={5} xl={{ size: 6 }}>
              <Input type="text" name="name" id="name" placeholder={i18n.t("contact.form.name")}
                onChange={this.handleChange}
                value={this.state.name}
                required />
            </Col>
          </FormGroup>
          <FormGroup row className="justify-content-around justify-content-sm-center justify-content-xl-center">
            <Label xs={12} sm={5} md={4} lg={5} xl={{ size: 3, offset: 3 }} for="email" className="">
              {i18n.t("contact.form.email")}<sup>*</sup>
            </Label>
            <Col xs={10} sm={5} md={5} lg={5} xl={{ size: 6 }}>
              <Input id="email"
                type="email" name="email" placeholder={i18n.t("contact.form.emailPlaceholder")}
                onChange={this.handleChange}
                value={this.state.email}
                required />
            </Col>
          </FormGroup>
          <FormGroup row className="justify-content-around justify-content-sm-center justify-content-xl-center">
            <Label xs={12} sm={5} md={4} lg={5} xl={{ size: 3, offset: 3 }} for="company" className="">{i18n.t("contact.form.company")}</Label>
            <Col xs={10} sm={5} md={5} lg={5} xl={{ size: 6 }}>
              <Input id="company"
                type="text" name="company" placeholder={i18n.t("contact.form.companyPlaceholder")}
                onChange={this.handleChange}
                value={this.state.company}
              />
            </Col>
          </FormGroup>
          <FormGroup row className="justify-content-around justify-content-sm-center justify-content-xl-center">
            <Label xs={12} sm={5} md={4} lg={5} xl={{ size: 3, offset: 3 }} for="phone" className="">{i18n.t("contact.form.phone")}</Label>
            <Col xs={10} sm={5} md={5} lg={5} xl={{ size: 6 }}>
              <Input id="phone"
                type="tel" name="phone" placeholder={i18n.t("contact.form.phonePlaceholder")}
                onChange={this.handleChange}
                value={this.state.phone}
              />
            </Col>
          </FormGroup>
          <FormGroup row className="justify-content-around justify-content-sm-center justify-content-xl-center">
            <Label xs={12} sm={5} md={4} lg={5} xl={{ size: 3, offset: 3 }} for="message" className="">{i18n.t("contact.form.message")}<sup>*</sup></Label>
            <Col xs={12} sm={5} md={5} lg={5} xl={{ size: 6 }}>
              <Input id="message"
                type="textarea" name="message" placeholder={i18n.t("contact.form.message")}
                onChange={this.handleChange}
                className={(this.state.message !== "") ? "filled" : ""}
                value={this.state.message}
                required />
            </Col>
          </FormGroup>
          <div className="d-flex justify-content-center justify-content-lg-start">
            <Button type={"submit"}
              className="d-flex justify-content-center justify-content-sm-end align-items-center col-6 col-sm-5 offset-sm-5 col-md-4 col-lg-2 offset-lg-9 col-xl-2 offset-xl-10">
              {i18n.t("contact.form.send")}
            <img
                src={sendImageUrl}
                alt="send"
                className="img-fluid ml-2"
                width={30}
              />
            </Button>
          </div>
        </Container>
      </Form>
    )
  }
}