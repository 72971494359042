import React from "react";
import i18n from "i18next";
import { Row, Col } from "reactstrap";

const HeroToggleSection = () => (
  <Row className="heroToggleSection">
    <Col xs="12" sm="6" className="appBanner banner p-0 d-flex justify-content-center align-items-center">
      <div className="heroText">
        <h2 className="text-center">
          <b>{i18n.t("service.weMakeApp")}</b>
        </h2>
      </div>
      <div className="content">
        <h3 className="text-center textDecorationAnchor">
          <b>{i18n.t("service.web.title")}</b>
          <div className="textDecoration" />
        </h3>
        <p>{i18n.t("service.web.description")}</p>
      </div>
    </Col>
    <Col xs="12" sm="6" className="webBanner banner p-0 d-flex justify-content-center align-items-center">
      <div className="heroText">
        <h2 className="text-center">
          <b>{i18n.t("service.weMakeWeb")}</b>
        </h2>
      </div>
      <div className="content">
        <h3 className="text-center textDecorationAnchor">
          <b>{i18n.t("service.app.title")}</b>
          <div className="textDecoration" />
        </h3>
        <p>{i18n.t("service.app.description")}</p>
      </div>
    </Col>
  </Row>
)

export default HeroToggleSection;