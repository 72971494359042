import React from "react";
import {
  Col, Card, CardImg, CardBody, CardTitle, CardSubtitle,
} from "reactstrap";
import { Link } from 'react-router-dom';

import i18n from '../../i18next';

export default class PortfolioSection extends React.Component {
  static defaultProps = {
    linkTo: "#",
    imageUrl: "",
    title: "",
    description: "",
    reverse: false
  }

  constructor(props) {
    super(props);
    this.state = {
      matrix3D: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
    };
  }

  /* use refernce as there are multiple card need to transfrom, cannot use id */
  targetContainer = React.createRef();
  transformTarget = React.createRef();
  /* ref: https://css-tricks.com/animate-a-container-on-mouse-over-using-perspective-and-transform/ */
  counter = 0;
  updateRate = 10;
  mouse = {
    /* origion position */
    ori_x: 0,
    ori_y: 0,
    /* relative position to center */
    relative_x: 0,
    relative_y: 0,
    /* set the coordinates (0,0) of our mouse object at the center of _element. */
    setOrigin: function (_element) {
      this.ori_x = _element.getBoundingClientRect().left + Math.floor(_element.getBoundingClientRect().width / 2);
      this.ori_y = _element.getBoundingClientRect().top + Math.floor(_element.getBoundingClientRect().height / 2);
    },
    /* updates the current position of our mouse object, relative to (0,0). */
    updatePosition: function (event) {
      var e = event || window.event;
      this.relative_x = e.clientX - this.ori_x;
      this.relative_y = (e.clientY - this.ori_y) * -1;
    },
    /* debug function */
    show: function () { return 'relative to center(' + this.relative_x + ', ' + this.relative_y + ')'; }
  }

  componentDidMount() {
  }

  mouseEnterHandler = (event) => {
    if (this.isTimeToUpdate()) {
      this.transformCaculation(event);
    }
  }

  mouseMoveMatrix = (event) => {
    this.transformCaculation(event);
  }

  onMouseLeaveMatrixOri = () => {
    this.onUpdateTransform(0, 0);
  }

  isTimeToUpdate = () => {
    /* decision for update is required.
       This is a way to reduce the number of calls to the transformCaculation() function. Improve the performance of our script. */
    return this.counter++ % this.updateRate === 0;
  };

  transformCaculation = (event) => {

    /* access to crrent attribute of ref */
    const transformTargetCur = this.transformTarget.current;
    const targetContainerCur = this.targetContainer.current;

    this.mouse.setOrigin(targetContainerCur);
    let mouseEvent = event || window.event;
    /* update mouse events */
    this.mouse.updatePosition(mouseEvent);

    /* update transform style */
    this.onUpdateTransform(
      (this.mouse.relative_y / transformTargetCur.getBoundingClientRect().height / 2).toFixed(2),
      (this.mouse.relative_x / transformTargetCur.getBoundingClientRect().width / 2).toFixed(2));
  }

  onUpdateTransform = (rotateXDeg = 0, roateYDeg = 0) => {
    this.setState({ transform: "rotateX(" + rotateXDeg + "deg) rotateY(" + roateYDeg + "deg)" });
  }

  render() {
    return (
      <Col size="12" sm="11" md="10"
        lg={(this.props.reverse) ? { size: 6, offset: 6 } : { size: 6 }}
        xl={(this.props.reverse) ? { size: 5, offset: 7 } : { size: 5 }}
        body="true" className="portfolioSection mb-2 mb-sm-3 mb-md-4 mb-lg-auto">
        <Link id={this.props.title} to={`/${i18n.language}/portfolio/${this.props.linkTo}`}>
          <div
            className="cardFrame"
            ref={this.targetContainer}
            onMouseEnter={(event) => this.mouseEnterHandler(event)}
            onMouseMove={(event) => this.mouseMoveMatrix(event)}
            onMouseLeave={() => this.onMouseLeaveMatrixOri()}
          >
            <div
              /* NOTE: use other div to wrapper card prevent reference glitch */
              ref={this.transformTarget}>
              <Card body className="transformTargetCard text-center"
                style={{ transform: this.state.transform }}>
                <CardImg src={this.props.imageUrl} alt={this.props.title} className="img-fluid p-lg-4" />
                <CardBody>
                  <h5 className="mb-4 mx-auto textDecorationAnchor">
                    <span>{this.props.title}</span>
                    <div className="textDecoration" />
                  </h5>
                  <h6 className="card-subtitle">{this.props.description}</h6>
                </CardBody>
              </Card>
            </div>
          </div>
        </Link>
      </Col>
    )
  }
}