import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import triangleSVG from "../../images/triangle.svg";
import _ from "lodash";

/* portfolio list */
import mammographyThumbnail from "../../images/portfolioMammography/thumbnail.jpg";
import mammographyWhiteLogo from "../../images/portfolioMammography/whiteLogo.png";
import ttapThumbnail from "../../images/portfolioTtap/thumbnail.jpg";
import ttapWhiteLogo from "../../images/portfolioTtap/whiteLogo.png";
import moonlightdogThumbnail from "../../images/portfolioMoonlightDog/thumbnail.jpg";
import moonlightdogWhiteLogo from "../../images/portfolioMoonlightDog/whiteLogo.png";
import matchfitThumbnail from "../../images/portfolioMatchfit/thumbnail.jpg";
import matchfitWhiteLogo from "../../images/portfolioMatchfit/whiteLogo.png";
import lunicaThumbnail from "../../images/portfolioLunica/thumbnail.jpg";
import lunicaWhiteLogo from "../../images/portfolioLunica/whiteLogo.png";
import compalThumbnail from "../../images/portfolioCompal/thumbnail.jpg";
import compalWhiteLogo from "../../images/portfolioCompal/whiteLogo.png";
import minefillServicesThumbnail from "../../images/portfolioMinefillServices/thumbnail.jpg";
import minefillServicesWhiteLogo from "../../images/portfolioMinefillServices/whiteLogo.png";
import moonspecsThumbnail from "../../images/portfolioMoonspecs/thumbnail.jpg";
import moonspecsWhiteLogo from "../../images/portfolioMoonspecs/whiteLogo.png";
import heroSpoonThumbnail from "../../images/portfolioHeroSpoon/thumbnail.jpg";
import heroSpoonWhiteLogo from "../../images/portfolioHeroSpoon/whiteLogo.png";
import iRetailARThumbnail from "../../images/portfolioiRetailAR/thumbnail.jpg";
import iRetailARWhiteLogo from "../../images/portfolioiRetailAR/whiteLogo.png";

import i18n from '../../i18next';


class PortfolioNavIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routersArr: [""],
      isHide: true,
      onShowTiny: false,
      scrollStatus: "top",/* [top, middel, botton] */
      scrollTopDestination: 0,
    };
  }

  /* reference for click arrow autoScroll finished */
  scrollRef = null;
  /* reference for timeout */
  timeoutRef = null;

  componentDidMount() {
    /* set navigation for first mount */
    this.setRoutesStatus(this.props.location.pathname);
  }

  componentWillReceiveProps(nextProps) {
    /* set navgation state by `withRouter` */
    this.setRoutesStatus(nextProps.location.pathname);
  }

  componentWillUnmount() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }
  }

  setRoutesStatus = (pathname) => {
    if (pathname.charAt(0) === "/") {
      const pathsArr = _.split(pathname.substr(1), "/");
      this.setState({ routersArr: pathsArr });
    }
  }

  onMouseEnterExtensionContainer = () => {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }
    /* prevent hover glitch */
    this.onChangeTinyExtensionStyle(true);
  }

  onSetTimeout = () => {
    this.timeoutRef = setTimeout(() => {
      this.setState({ isHide: true, onShowTiny: false });
    }, 3000);
  }

  onToggleStatusChange = () => {
    this.setState({ isHide: !this.state.isHide });
  }

  onChangeTinyExtensionStyle = (onShowTinyState = false) => {
    /* trigger onShowTiny class as not extension whole container */
    if (this.state.isHide) {
      this.setState({ onShowTiny: onShowTinyState });
    }
  }

  onScrollDetection = () => {
    const portfoliosContainer = document.getElementById("portfoliosContainer");
    if (portfoliosContainer.scrollHeight === portfoliosContainer.offsetHeight + portfoliosContainer.scrollTop) {
      clearInterval(this.scrollRef);
      this.setState({ scrollStatus: "bottom" });
    } else if (portfoliosContainer.scrollTop !== 0) {
      if (this.state.scrollTopDestination === portfoliosContainer.scrollTop && this.scrollRef) {
        clearInterval(this.scrollRef);
      }
      this.setState({ scrollStatus: "middle" });
    } else {
      clearInterval(this.scrollRef);
      this.setState({ scrollStatus: "top" });
    }
  }

  onScrollDown = () => {
    clearInterval(this.scrollRef);
    const portfoliosContainer = document.getElementById("portfoliosContainer");
    const scrollOnSection = portfoliosContainer.offsetHeight / 3;
    const scrollTopDestination = portfoliosContainer.scrollTop + scrollOnSection;
    this.setState({ scrollTopDestination: scrollTopDestination });
    this.scrollRef = setInterval(() => {
      if (portfoliosContainer.scrollTop !== scrollTopDestination) {
        portfoliosContainer.scrollTop = portfoliosContainer.scrollTop + 3;
      }
    }, 8);
  }

  onScrollUp = () => {
    clearInterval(this.scrollRef);
    const portfoliosContainer = document.getElementById("portfoliosContainer");
    const scrollOnSection = portfoliosContainer.offsetHeight / 3;
    const scrollTopDestination = portfoliosContainer.scrollTop - scrollOnSection;
    this.setState({ scrollTopDestination: scrollTopDestination });
    this.scrollRef = setInterval(() => {
      if (portfoliosContainer.scrollTop !== scrollTopDestination) {
        portfoliosContainer.scrollTop = portfoliosContainer.scrollTop - 3;
      }
    }, 8);
  }

  onNavPress = () => {
    this.setState({ isHide: true, onShowTiny: false });
  }

  render() {
    return (
      (this.state.routersArr[1] === "about" || this.state.routersArr[1] === "portfolio" || this.state.routersArr[1] === "service") ? // TODO(vagrants): find better solution for this route issue
        <div className="portfolioNavIndicator">
          <div
            className={`toggleContainer d-flex align-items-center ${(this.state.isHide) ? "" : "onExtension"} ${(this.state.onShowTiny) ? "onShowTiny" : ""}`}>
            <div className="extensionContainer"
              onMouseEnter={() => this.onMouseEnterExtensionContainer()}
              onMouseLeave={() => this.onSetTimeout()}
            >
              {(this.state.scrollStatus === "top") ?
                <div className="circleHalo mx-auto my-2" />
                :
                <div className="triangleDown rotateToUp mx-auto my-2"
                  onClick={() => this.onScrollUp()} />
              }
              <div className="portfoliosContainer" id="portfoliosContainer"
                onScroll={() => this.onScrollDetection()}
              >
                <PortfolioSection
                  thumbnail={mammographyThumbnail}
                  portfolioTitle={i18n.t("mammographyPortfolio.abbriTitle")}
                  logoUrl={mammographyWhiteLogo}
                  linkTo={"mammography"}
                  onNavPress={() => this.onNavPress()}
                />
                <PortfolioSection
                  thumbnail={ttapThumbnail}
                  portfolioTitle={i18n.t("ttapPortfolio.abbriTitle")}
                  logoUrl={ttapWhiteLogo}
                  linkTo={"ttap"}
                  onNavPress={() => this.onNavPress()}
                />
                <PortfolioSection
                  thumbnail={moonlightdogThumbnail}
                  portfolioTitle={i18n.t("MoonlightdogPortfolio.abbriTitle")}
                  logoUrl={moonlightdogWhiteLogo}
                  linkTo={"moonlight-dog-cafe"}
                  onNavPress={() => this.onNavPress()}
                />
                <PortfolioSection
                  thumbnail={matchfitThumbnail}
                  portfolioTitle={i18n.t("MatchfitPortfolio.abbriTitle")}
                  logoUrl={matchfitWhiteLogo}
                  linkTo={"matchfit"}
                  onNavPress={() => this.onNavPress()}
                />
                <PortfolioSection
                  thumbnail={lunicaThumbnail}
                  portfolioTitle={i18n.t("LunicaPortfolio.abbriTitle")}
                  logoUrl={lunicaWhiteLogo}
                  linkTo={"lunica"}
                  onNavPress={() => this.onNavPress()}
                />
                <PortfolioSection
                  thumbnail={compalThumbnail}
                  portfolioTitle={i18n.t("CompalPortfolio.title")}
                  logoUrl={compalWhiteLogo}
                  linkTo={"compal"}
                  onNavPress={() => this.onNavPress()}
                />
                <PortfolioSection
                  thumbnail={minefillServicesThumbnail}
                  portfolioTitle={i18n.t("MinefillServicesPortfolio.abbriTitle")}
                  logoUrl={minefillServicesWhiteLogo}
                  linkTo={"minefill-service"}
                  onNavPress={() => this.onNavPress()}
                />
                <PortfolioSection
                  thumbnail={moonspecsThumbnail}
                  portfolioTitle={i18n.t("MoonspecsPortfolio.abbriTitle")}
                  logoUrl={moonspecsWhiteLogo}
                  linkTo={"moonspecs"}
                  onNavPress={() => this.onNavPress()}
                />
                <PortfolioSection
                  thumbnail={heroSpoonThumbnail}
                  portfolioTitle={i18n.t("HeroSpoonPortfolio.abbriTitle")}
                  logoUrl={heroSpoonWhiteLogo}
                  linkTo={"herospoon"}
                  onNavPress={() => this.onNavPress()}
                />
                <PortfolioSection
                  thumbnail={iRetailARThumbnail}
                  portfolioTitle={i18n.t("iRetailARPortfolio.abbriTitle")}
                  logoUrl={iRetailARWhiteLogo}
                  linkTo={"iRetailAR"}
                  onNavPress={() => this.onNavPress()}
                />
              </div>
              {(this.state.scrollStatus === "bottom") ?
                <div className="circleHalo mx-auto my-2" />
                :
                <div className="triangleDown mx-auto my-2"
                  onClick={() => this.onScrollDown()} />
              }
            </div>
            <div className="collapseContainer">
              <Button outline color="secondary" className="d-flex align-items-center p-0"
                onClick={() => this.onToggleStatusChange()}>
                <img src={triangleSVG} alt="arrowRight" className="img-fluid p-2 pt-3"
                  onMouseEnter={() => this.onChangeTinyExtensionStyle(true)}
                  onMouseLeave={() => this.onChangeTinyExtensionStyle(false)}
                />
                <p className="portfolioText m-0 text-uppercase p-2 pr-3"
                  onMouseEnter={() => this.onChangeTinyExtensionStyle(true)}
                  onMouseLeave={() => this.onChangeTinyExtensionStyle(false)}
                >{i18n.t("header.portfolio")}</p>
              </Button>
            </div>
          </div>
        </div>
        :
        null
    )
  }
}

const PortfolioSection = ({ thumbnail = "", portfolioTitle = "", logoUrl = "", linkTo = "", onNavPress = () => { } }) => (
  <Link id={portfolioTitle} to={`/${i18n.language}/portfolio/${linkTo}`} className="text-center" onClick={onNavPress}>
    <div className="portfolioSection"
      style={{ backgroundImage: "url(" + thumbnail + ")" }}>
      <div className="grayCover d-flex p-5 justify-content-center align-items-center">
        <p className="m-0">{portfolioTitle}</p>
        <img src={logoUrl} alt={portfolioTitle} className="whitelogo img-fluid" />
      </div>
    </div>
  </Link>
)

export default withRouter(PortfolioNavIndicator);