import React from "react";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import i18n from '../../i18next';
import { Container, Row } from "reactstrap";
import heroImageUrl from "../../images/portfolioMatchfit/matchfitHero.jpg";
import HeroContainer from "./commons/HeroContainer";
import IntroductionSection from "./commons/IntroductionSection";
import matchfitLogo from "../../images/portfolioMatchfit/logo.png";
import { DesktopView, MobileiXBlackView } from "./commons/WorkGallery";
import { ConceptKeyPointsListSection } from "./commons/ConceptSection";
import NextIndicatorSection from "./commons/NextIndicatorSection";

/* screenshots */
import desktopScreenshot from "../../images/portfolioMatchfit/desktopSH.jpg";
import mobileScreenshotURL01 from "../../images/portfolioMatchfit/desktopScreenshot01.jpg";
import mobileScreenshotURL02 from "../../images/portfolioMatchfit/desktopScreenshot02.jpg";
import mobileScreenshotURL03 from "../../images/portfolioMatchfit/desktopScreenshot03.jpg";
import mobileScreenshotURL04 from "../../images/portfolioMatchfit/desktopScreenshot04.jpg";
import mobileScreenshotURL05 from "../../images/portfolioMatchfit/desktopScreenshot05.jpg";
import mobileScreenshotURL06 from "../../images/portfolioMatchfit/desktopScreenshot06.jpg";
import mobileScreenshotURL07 from "../../images/portfolioMatchfit/desktopScreenshot07.jpg";
import mobileScreenshotURL08 from "../../images/portfolioMatchfit/desktopScreenshot08.jpg";
import mobileScreenshotURL09 from "../../images/portfolioMatchfit/desktopScreenshot09.jpg";

export default class Matchfit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { windowScrollY: 0 };
  }

  componentDidMount() {
    /* inital scoll settting to be on the top */
    window.scrollTo(0, 0);

    window.onscroll = () => {
      /* need to set scroll in parent prevent glitch */
      this.setState({ windowScrollY: window.scrollY });
    }
  }

  render() {
    const url = new URL(window.location.href);
    const helmetContent = "Gopomo built websites and implement apps. MatchFit app, both for iOS and Android, connects athletes, gyms, and coaches in a social network that allows people to find new training partners for sports and fitness, and allows gyms and coaches to connect their students and be found by local individuals.";
    const helmetTitle = `MatchFit - ${i18n.t("jsonLdOrganization.shortCompName")}`;
    return (
      <div className="specificPortfolio">
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetContent} />
          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:url" content={`${url.href}`} />
          <meta property="og:image" content={mobileScreenshotURL01} />
          <meta property="og:description" content={helmetContent} />
          {/* canonical url, see: https://support.google.com/webmasters/answer/139066 */}
          <link rel="canonical" href={`${url.href}`} />
        </Helmet>
        <HeroContainer
          heroImageUrl={heroImageUrl}
          title="MatchFit"
        />
        <Container fluid={true} className="mainSections">
          <div className="pt-2 pt-md-3 pt-lg-4">
            <IntroductionSection
              title="MatchFit"
              squareImgUrl={matchfitLogo}
              description={i18n.t("MatchfitPortfolio.introduction")}
              yearMonthText="May 2017"
              windowScrollY={this.state.windowScrollY}
              externalLinkURL={"http://matchfitapp.com/"}
            />
            <Container>
              <Row className="showCaseSection p-1 p-sm-2 px-lg-4 py-md-3">
                <DesktopView
                  screenshotUrls={[desktopScreenshot]}
                />
                <MobileiXBlackView
                  screenshotUrls={[mobileScreenshotURL01, mobileScreenshotURL02, mobileScreenshotURL03, mobileScreenshotURL04, mobileScreenshotURL05, mobileScreenshotURL06, mobileScreenshotURL07, mobileScreenshotURL08, mobileScreenshotURL09,]}
                />
                <ConceptKeyPointsListSection
                  keyPoints={i18n.t("MatchfitPortfolio.keypoints", { returnObjects: true })}
                />
              </Row>
            </Container>
            <NextIndicatorSection
              linkTo={"/portfolio/lunica"}
              linkText={i18n.t("LunicaPortfolio.title")}
              windowScrollY={this.state.windowScrollY}
            />
          </div>
        </Container>
      </div>
    )
  }
}