import React from "react";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import _ from "lodash";
import metaImage from "../../images/contact/meta.jpg";
import i18next from "../../i18next";
import { Container } from "reactstrap";
// import HeroContainer from "../common/HeroContainer";
import { ContactSection, LogoBanner } from "./ContactSections";
import ConactForm from "./ContactForm";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { windowScrollY: 0 };
  }

  /* mounted status, to determine whether to setState */
  mounted = false;

  componentDidMount() {
    this.mounted = true;
    /* inital scoll settting to be on the top */
    window.scrollTo(0, 0);
    window.onscroll = () => {
      if (this.mounted) {
        /* need to set scroll in parent prevent glitch */
        this.setState({ windowScrollY: window.scrollY });
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const url = new URL(window.location.href);
    const helmetTitle = `${_.capitalize(i18next.t("contact.title"))} - ${i18next.t("jsonLdOrganization.shortCompName")}`;
    const helmetDesContent = `${i18next.t("contact.metaDes")}`;

    return (
      <div className="contact">
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetDesContent} />

          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:url" content={`${url.href}`} />
          <meta property="og:description" content={helmetDesContent} />

          <meta property="og:image" content={metaImage} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="300" />
          <meta property="og:image:alt" content={helmetTitle} />

          <link rel="canonical" href={`${url.href}`} />
        </Helmet>
        {/* <HeroContainer
          title="CONTACT"
          splashQuote={`LET'S \n BUILD \n SOMETHING GREAT`}
          subTitle="Connect With Us"
        /> */}
        <Container fluid={true} className="mainSections">
          <Container className="py-2 py-md-3 py-lg-4">
            <ContactSection
              title={i18next.t("contact.likeToChat")}
              description={i18next.t("contact.contactUsWay")}
              contactThroughApp={i18next.t("contact.contactThroughApp")}
            />
            <LogoBanner windowScrollY={this.state.windowScrollY} />
            <ConactForm />
          </Container>
        </Container>
      </div>
    )
  }
}