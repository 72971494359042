import React from "react";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import i18n from "../../i18next";
import { Container, Row } from "reactstrap";
import heroImageUrl from "../../images/portfolioMammography/heroBanner.jpg";
import HeroContainer from "./commons/HeroContainer";
import IntroductionSection from "./commons/IntroductionSection";
import logo from "../../images/portfolioMammography/logo.png";
import { DesktopView, MobileiXBlackView } from "./commons/WorkGallery";
import { ConceptKeyPointsListSection } from "./commons/ConceptSection";
import NextIndicatorSection from "./commons/NextIndicatorSection";

/* screenshots */
import desktopScreenshotURL01 from "../../images/portfolioMammography/desktopScreenshot01.jpg";
import desktopScreenshotURL02 from "../../images/portfolioMammography/desktopScreenshot02.jpg";
import desktopScreenshotURL03 from "../../images/portfolioMammography/desktopScreenshot03.jpg";
import desktopScreenshotURL04 from "../../images/portfolioMammography/desktopScreenshot04.jpg";
import desktopScreenshotURL05 from "../../images/portfolioMammography/desktopScreenshot05.jpg";
import desktopScreenshotURL06 from "../../images/portfolioMammography/desktopScreenshot06.jpg";
import desktopScreenshotURL07 from "../../images/portfolioMammography/desktopScreenshot07.jpg";

import mobileScreenshotURL01 from "../../images/portfolioMammography/mobileScreenshot01.jpg";
import mobileScreenshotURL02 from "../../images/portfolioMammography/mobileScreenshot02.jpg";
import mobileScreenshotURL03 from "../../images/portfolioMammography/mobileScreenshot03.jpg";
import mobileScreenshotURL04 from "../../images/portfolioMammography/mobileScreenshot04.jpg";
import mobileScreenshotURL05 from "../../images/portfolioMammography/mobileScreenshot05.jpg";
import mobileScreenshotURL06 from "../../images/portfolioMammography/mobileScreenshot06.jpg";
import mobileScreenshotURL07 from "../../images/portfolioMammography/mobileScreenshot07.jpg";
import mobileScreenshotURL08 from "../../images/portfolioMammography/mobileScreenshot08.jpg";
import mobileScreenshotURL09 from "../../images/portfolioMammography/mobileScreenshot09.jpg";
import mobileScreenshotURL10 from "../../images/portfolioMammography/mobileScreenshot10.jpg";
import mobileScreenshotURL11 from "../../images/portfolioMammography/mobileScreenshot11.jpg";
import mobileScreenshotURL12 from "../../images/portfolioMammography/mobileScreenshot12.jpg";

export default class Mammography extends React.Component {
  constructor(props) {
    super(props);
    this.state = { windowScrollY: 0 };
  }

  componentDidMount() {
    /* inital scoll settting to be on the top */
    window.scrollTo(0, 0);

    window.onscroll = () => {
      /* need to set scroll in parent prevent glitch */
      this.setState({ windowScrollY: window.scrollY });
    }
  }

  render() {
    const url = new URL(window.location.href);
    const helmetContent = i18n.t("mammographyPortfolio.introduction");
    const helmetTitle = `${i18n.t("mammographyPortfolio.abbriTitle")} - ${i18n.t("jsonLdOrganization.shortCompName")}`;
    return (
      <div className="specificPortfolio">
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetContent} />
          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:url" content={`${url.href}`} />
          <meta property="og:image" content={desktopScreenshotURL01} />
          <meta property="og:description" content={helmetContent} />
          {/* canonical url, see: https://support.google.com/webmasters/answer/139066 */}
          <link rel="canonical" href={`${url.href}`} />
        </Helmet>
        <HeroContainer
          heroImageUrl={heroImageUrl}
          title={i18n.t("mammographyPortfolio.title")}
        />
        <Container fluid={true} className="mainSections">
          <div className="pt-2 pt-md-3 pt-lg-4">
            <IntroductionSection
              title={i18n.t("mammographyPortfolio.abbriTitle")}
              squareImgUrl={logo}
              description={i18n.t("mammographyPortfolio.introduction")}
              yearMonthText="March 2019"
              windowScrollY={this.state.windowScrollY}
              externalLinkURL={"https://mammography-bus.ttshb.gov.tw"}
            />
            <Container>
              <Row className="showCaseSection p-1 p-sm-2 px-lg-4 py-md-3">
                <DesktopView
                  screenshotUrls={[desktopScreenshotURL01, desktopScreenshotURL02, desktopScreenshotURL03, desktopScreenshotURL04, desktopScreenshotURL05, desktopScreenshotURL06, desktopScreenshotURL07,]}
                />
                <MobileiXBlackView
                  screenshotUrls={[mobileScreenshotURL01, mobileScreenshotURL02, mobileScreenshotURL03, mobileScreenshotURL04, mobileScreenshotURL05, mobileScreenshotURL06, mobileScreenshotURL07, mobileScreenshotURL08, mobileScreenshotURL09, mobileScreenshotURL10, mobileScreenshotURL11, mobileScreenshotURL12]}
                />
                <ConceptKeyPointsListSection
                  keyPoints={i18n.t("mammographyPortfolio.keypoints", { returnObjects: true })}
                />
              </Row>
            </Container>
            <NextIndicatorSection
              linkTo={"/portfolio/ttap"}
              linkText={i18n.t("ttapPortfolio.title")}
              windowScrollY={this.state.windowScrollY}
            />
          </div>
        </Container>
      </div>
    )
  }
}