import React from "react";
import i18n from "../../i18next";
import { Row, Col } from "reactstrap";
// import imageUrl from "../../images/gopomoText.svg";

const Content = ({ title = "", subtitle = "", article = "" }) => (
  <div className={"pt-3 pb-2 pb-md-2 pb-lg-5 pl-md-4"}>
    <h2 className="text-uppercase mb-sm-2 mx-auto mx-md-0">{title}</h2>
    <p>{article}</p>
  </div>
)

const AboutSections = () => (
  <Row className="aboutSections justify-content-center py-5 py-md-3 py-lg-4 pb-xl-0">
    <Col xs="12" sm="12" md="3" lg="3" xl="3">
      <h3 className="title mx-auto mb-3 my-md-auto text-center pb-2">{i18n.t("home.hello")}</h3>
      {/* <h5 className="subTitle mt-2 mt-md-3 text-center">{i18n.t("home.aboutUs")}</h5> */}
    </Col>
    <Col className="mt-md-4" xs="12" sm="11" md="9" lg="6" xl="6">
      <Content
        title={i18n.t("home.International Experience")}
        article={i18n.t("home.internationalArticle")}
      />
      <Content
        title={i18n.t("home.Integrated Team Service")}
        article={i18n.t("home.teamArticle")}
      />
    </Col>
    {/* <img className={"img-fluid py-3 p-lg-5"} src={imageUrl} alt="Gopomo" /> */}
  </Row>
)


export default AboutSections;