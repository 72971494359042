import React from "react";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import i18next from "../../i18next";
import _ from "lodash";
import Typing from 'react-typing-animation';
import videoHomeUrl from "../../video/homeVideo.mp4";
import { Container, Row, Col } from "reactstrap";

import metaImage01 from "../../images/home/meta01.jpg";
import metaImage02 from "../../images/home/meta02.jpg";
import NewsSection from "./NewsSection";
import HeroContainer from "../common/HeroContainer";
import AboutSections from "./AboutSections";
import PortfolioCitiesSection from "./PortfolioCitiesSection";


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowArrowDown: false,
    };
  }

  /* reference for mounted */
  mounted = false;

  componentDidMount() {
    this.mounted = true;
    /* inital scoll settting to be on the top */
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.sloganSetIntervalRef) {
      clearInterval(this.sloganSetIntervalRef);
      this.sloganSetIntervalRef = null;
    }
  }

  showArrowDown = () => {
    this.setState({
      isShowArrowDown: true
    });
  }

  onScrollDownToHeroContent = () => {
    document.querySelector('.heroContent').scrollIntoView({
      behavior: 'smooth'
    });
  }

  renderSloganSub = () => {
    if (_.get(i18next, 'language') === "zh" || _.get(i18next, 'language') === "zh-hant") {
      /*  Bernie don't likes 'zh-hant', change to 'zh' */
      return (
        <p className="sloganSub text-center text-white">
          <Typing.Speed ms={100} />以你我的創意
          <Typing.Speed ms={1} /><Typing.Delay ms={1000} />&nbsp;&nbsp;&nbsp;
          <Typing.Speed ms={100} /><Typing.Delay ms={500} />讓世界
          <Typing.Delay ms={500} /><Typing.Speed ms={260} />難以抗拒
          <Typing.Speed ms={200} /><Typing.Delay ms={1500} />
        </p>
      )
    } else {
      return (
        <p className="sloganSub text-center text-white">
          <Typing.Speed ms={100} />A
          <Typing.Speed ms={1} />&nbsp;&nbsp;&nbsp;
          <Typing.Speed ms={100} />DIGITAL
          <Typing.Speed ms={1} /><Typing.Delay ms={500} />&nbsp;&nbsp;&nbsp;
          <Typing.Speed ms={100} /><Typing.Delay ms={500} />CREATIVE
          <Typing.Speed ms={1} /><Typing.Delay ms={500} />&nbsp;&nbsp;&nbsp;
          <Typing.Speed ms={100} /><Typing.Delay ms={500} />STUDIO
          <Typing.Speed ms={200} /><Typing.Delay ms={1500} />
        </p>
      )
    }
  }

  render() {
    const url = new URL(window.location.href);

    const jsonLdOrganization = {
      "@context": "http://schema.org/",
      "@type": "Organization",
      "name": `${i18next.t("jsonLdOrganization.name")}`,
      "brand": `${i18next.t("jsonLdOrganization.name")}`,
      "description": `${i18next.t("home.metaDes")}`,
      "url": url.href,
      "logo": url.origin + "/GopomoLogo.jpg" /* logo definition: https://developers.google.com/search/docs/data-types/logo */,
      "contactPoint": [{
        "@type": "ContactPoint",
        "telephone": "+1-647-295-0969",
        "contactType": "customer service",
        "areaServed": ["CA", "TW", "US", "CN", "HK", "JP", "KR"],
        "availableLanguage": ["English", "Chinese"]
      }],
      "sameAs": [
        "https://www.facebook.com/GopomoInc/"
      ]
    }

    const helmetTitle = `${i18next.t("jsonLdOrganization.name")}`;
    const helmetDesContent = `${i18next.t("home.metaDes")}`;

    let news = i18next.t("home.newsList", { returnObjects: true });
    news.map(eachNews => (
      _.assign(eachNews, { date: new Date(eachNews.date) })
    ));
    return (
      <div className="home">
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetDesContent} />
          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content={i18next.t("home.metaTitle")} />
          <meta property="og:url" content={`${url.href}`} />
          <meta property="og:description" content={helmetDesContent} />

          <meta property="og:video" content={videoHomeUrl} />
          <meta property="og:video:type" content="video/mp4" />
          <meta property="og:video:width" content="1280" />
          <meta property="og:video:height" content="454" />

          <meta property="og:image" content={metaImage01} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="300" />
          <meta property="og:image:alt" content="GOPOMO Inc." />
          <meta property="og:image" content={metaImage02} />
          {/* canonical url, see: https://support.google.com/webmasters/answer/139066 */}
          <link rel="canonical" href={`${url.href}`} />

          {/* organization ld+json */}
          <script type="application/ld+json">
            {`${JSON.stringify(jsonLdOrganization)}`}
          </script>
        </Helmet>
        <Container fluid={true}>
          <Row className="viewPort">
            <Col className="p-0">
              <video className="embed-responsive-item" autoPlay={true} loop muted>
                <source src={videoHomeUrl} type="video/mp4" />
              </video>
              <div className="sloganContainer flex-column">
                <Typing cursorClassName="typingCursor" speed={200} startDelay={500} onFinishedTyping={() => this.showArrowDown()}>
                  <p className="sloganMain mb-5 text-center text-white">
                    {i18next.t("home.companyName")}
                  </p>

                  <Typing.Delay ms={1500} />
                  {this.renderSloganSub()}

                </Typing>
                <button className={`btnLinkBaseStyle arrowDown m-0 ${(this.state.isShowArrowDown) ? "" : "hidden"}`} onClick={() => this.onScrollDownToHeroContent()}>
                  ↓
                </button>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="about">
          <HeroContainer
            title={i18next.t("home.title")}
            splashQuote={i18next.t("home.splashQuote")}
            subTitle={i18next.t("home.subTitle")}
          />
          <Container fluid={false} className="mainSections">
            <AboutSections />
            <NewsSection
              newsArr={_.take(_.reverse(_.sortBy(news, (eachNews) => eachNews.date)), 5)}
            />
            <PortfolioCitiesSection />
          </Container>
        </div>
      </div>
    )
  }
}