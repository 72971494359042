import _ from 'lodash';
import React, { Component } from 'react';
// import "@babel/polyfill";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import ReactGA from "react-ga"; /* https://github.com/react-ga/react-ga */
// import favIconUrl from "./images/favicon.ico";
import perLoadFront from "./fonts/PingFangSC-Regular.woff2";
import 'bootstrap/dist/css/bootstrap.css';
import './css/App.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

/* children components */
import Landing from "./components/Landing";
import Header from "./components/Header";
import PortfolioNavIndicator from "./components/common/PortfolioNavIndicator";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import Service from "./components/Service";
import Job from "./components/Job";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

/* each specific portfolio */
import Mammography from "./components/PortfolioCollections/Mammography";
import Ttap from "./components/PortfolioCollections/Ttap";
import Moonlightdog from "./components/PortfolioCollections/Moonlightdog";
import Matchfit from "./components/PortfolioCollections/Matchfit";
import Lunica from "./components/PortfolioCollections/Lunica";
import Compal from "./components/PortfolioCollections/Compal";
import MinefillServices from "./components/PortfolioCollections/MinefillServices";
import Moonspecs from "./components/PortfolioCollections/Moonspecs";
import HeroSpoon from "./components/PortfolioCollections/HeroSpoon";
import iRetailAR from "./components/PortfolioCollections/iRetailAR";

/* error pages */
import NotFound from "./components/errors/NotFound";

// For localization
import i18n from './i18next';
import LocaleDetector from './LocaleDetector';

/* for google anaylics - initialization */
ReactGA.initialize("UA-47986427-1");

const RouterDefination = () => (
  <Router>
    <div>
      <Route exact path="/" component={LocaleDetector} />
      <Route path='/:lang/' children={({ match, location, ...options }) => {
        // i18n redirection setup
        const lang = _.get(match, 'params.lang');
        if (_.indexOf(i18n.languages, lang) < 0) { // if lang is not valid language code, redirect to /:lang/
          return <Redirect to={`/${i18n.language}${location.pathname}`} />;
        }
        if (lang === 'zh-hant') { // Bernie don't likes 'zh-hant', change to 'zh'
          const redirectUrl = _.replace(location.pathname, new RegExp('zh-hant', "g"), 'zh');;
          return <Redirect to={redirectUrl} />;
        }
        if (lang !== i18n.language) { // change language if it's not current language
          i18n.changeLanguage(lang);
        }

        /* for google analytics - tracking */
        ReactGA.pageview(location.pathname);

        return (
          <div>
            <Helmet>
              <html lang={lang} />
            </Helmet>
            <Header />
            <PortfolioNavIndicator />
            <Switch>
              <Route exact path="/:lang/" component={Home} />
              {/* <Route path="/:lang/portfolio" component={Portfolio} /> */}
              <Route path="/:lang/portfolio" foo="bar" component={PortfolioCollections} />
              <Route path="/:lang/service" component={Service} />
              <Route path="/:lang/job" component={Job} />
              <Route path="/:lang/contact" component={Contact} />
              <Route component={NotFound} status={404} />
            </Switch>
            <Footer />
          </div>
        );
      }} />
    </div>
  </Router>
)

const PortfolioCollections = (opts) => {
  const { match } = opts;
  return (
    <Switch>
      <Route path={`${match.url}/mammography`} component={Mammography} exact />
      <Route path={`${match.url}/ttap`} component={Ttap} exact />
      <Route path={`${match.url}/moonlight-dog-cafe`} component={Moonlightdog} exact />
      <Route path={`${match.url}/matchfit`} component={Matchfit} exact />
      <Route path={`${match.url}/lunica`} component={Lunica} exact />
      <Route path={`${match.url}/compal`} component={Compal} exact />
      <Route path={`${match.url}/minefill-service`} component={MinefillServices} exact />
      <Route path={`${match.url}/moonspecs`} component={Moonspecs} exact />
      <Route path={`${match.url}/herospoon`} component={HeroSpoon} exact />
      <Route path={`${match.url}/iRetailAR`} component={iRetailAR} exact />
      <Route path={`${match.url}`} component={Portfolio} />
    </Switch>
  )
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { showLoading: true };
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    /* show loading only on, first landing on Home page */
    if (url.pathname === "/" || url.pathname === "/en/" || url.pathname === "/zh-hant/" || url.pathname === "/zh/") {
      setTimeout(() => {
        this.setState({ showLoading: false })
      }, 3500);
    } else {
      this.setState({ showLoading: false })
    }
  }

  render() {
    const url = new URL(window.location.href);
    const helmetContent = "Gopomo started as a small project when all of its members were students. We built websites and implement application. Our friendly and motivated team is inspired by user experiences and fast growing technologies. In our view, software and design are limited only by the extent of our imagination. You say it, and we will build it.";
    const helmetTitle = "Gopomo Inc.";

    return (
      <div className="App">
        <Helmet>
          <title>{helmetTitle}</title>
          <link rel="manifest" href={url.origin + "/manifest.json"} />
          {/* <link rel="shortcut icon" href={url.origin + "/favicon.ico"} /> */}
          {/* <link rel="shortcut icon" href={favIconUrl} /> */}
          <meta name="description" content={helmetContent} />

          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content="website" />
          <meta property="og:type" content={helmetTitle} />
          <meta property="og:description" content={helmetContent} />

          {/* Twitter Card data, see: https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/getting-started.html */}
          <meta name="twitter:card" value="summary" />

          {/* font-family `PingFangSC-Regular` preload */}
          <link rel="preload" href={perLoadFront} as={"font"} type="font/woff2"/>
        </Helmet>
        {(this.state.showLoading) ?
          <Landing /> : <RouterDefination />
        }
      </div>
    );
  }
}

export default App;
