import React from "react";
import HeroContainer from "../common/HeroContainer";

import i18n from '../../i18next';

export default class NotFound extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="error error404">
        <HeroContainer
          title={i18n.t('errors.404.title')}
          splashQuote={i18n.t('errors.404.quote')}
          subTitle={i18n.t('errors.404.subTitle')}
        />
      </div>
    )
  }
}