import React from "react";
import i18n from "../../i18next";
import { Row, Col } from "reactstrap";

const ToggleSection = ({ onScrollToForm = () => { } }) => (
  <Row className="toggleSection flex-row-reverse">
    <Col xs="12" sm="4" className="contact">
      <button className="btnLinkBaseStyle btn-block" onClick={onScrollToForm}>
        <h2 className="text-center py-4 py-xl-5"><i>{i18n.t("job.contact")}</i></h2>
      </button>
    </Col>
    <Col xs="12" sm="8" className="collaborate">
      <h2 className="text-center py-4 py-xl-5"><i>{i18n.t("job.collaborate")}</i></h2>
    </Col>
  </Row>
)

export default ToggleSection;