import React from "react";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import metaImage from "../../images/service/meta.jpg";
import i18next from "../../i18next";
import { Container } from "reactstrap";
import HeroContainer from "../common/HeroContainer";
// import ServicgeSection from "./ServiceSection";
import HeroToggleSection from "./HeroToggleSection";
import ThematicBanner from "./ThematicBanner";

export default class Service extends React.Component {

  componentDidMount() {
    /* inital scoll settting to be on the top */
    window.scrollTo(0, 0);
  }

  render() {
    const url = new URL(window.location.href);
    const helmetTitle = `${i18next.t("service.title")} - ${i18next.t("jsonLdOrganization.shortCompName")}`;
    const helmetDesContent = `${i18next.t("service.metaDes")}`;

    return (
      <div className="service">
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetDesContent} />

          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:url" content={`${url.href}`} />
          <meta property="og:description" content={helmetDesContent} />

          <meta property="og:image" content={metaImage} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="300" />
          <meta property="og:image:alt" content={i18next.t("service.subTitle")} />

          {/* canonical url, see: https://support.google.com/webmasters/answer/139066 */}
          <link rel="canonical" href={`${url.href}`} />
        </Helmet>
        <HeroContainer
          title={i18next.t("service.title")}
          splashQuote={i18next.t("service.splashQuote")}
          subTitle={i18next.t("service.subTitle")}
        />
        <Container fluid={true} className="mainSections">
          {/* <Container> */}
          {/* <ServiceSection
              title="Lorem Ipsum"
              description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
              imageUrl="http://via.placeholder.com/640x420"
              isReverseOrder={true}
            />
            <ServiceSection
              title="Lorem Ipsum"
              description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
              imageUrl="http://via.placeholder.com/640x420"
            /> */}
          <HeroToggleSection />
          <Container>
            <ThematicBanner
              title="Bernie Ting"
              description="When it comes to innovation, sometimes it's not only about the why's, but also about asking why not's."
            />
            {/* <ServiceSection
              title="Lorem Ipsum"
              description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
              imageUrl="http://via.placeholder.com/640x420"
              isReverseOrder={true}
            />
            <ServiceSection
              title="Lorem Ipsum"
              description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
              imageUrl="http://via.placeholder.com/640x420"
            /> */}
          </Container>
        </Container>
      </div>
    )
  }
}