import React from "react";
import _ from "lodash";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { Container, Nav, NavItem, Col, Navbar, Collapse, NavLink, Button } from 'reactstrap';
import logoFrameImgUrl from "../images/logoFrame.png";

import i18n from '../i18next';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { routersArr: [""] };
  }

  componentDidMount() {
    /* set navigation for first mount */
    this.setRoutesStatus(this.props.location.pathname);
  }

  componentWillReceiveProps(nextProps) {
    /* set navgation state by `withRouter` */
    this.setRoutesStatus(nextProps.location.pathname);
  }

  setRoutesStatus = (pathname) => {
    if (pathname.charAt(0) === "/") {
      const pathsArr = _.split(pathname.substr(1), "/");
      /* remove pathsArr[] = "" for redundant home path */
      _.pull(pathsArr, "");
      this.setState({ routersArr: pathsArr });
    }
  }

  render() {
    const url = new URL(window.location.href);

    /* for breadcurmb jsonLd: https://developers.google.com/search/docs/data-types/breadcrumb */
    let jsonLdBreadcrumb = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": []
    }

    let itemListElement;
    /* split lang */
    const lang = this.state.routersArr[0];

    /* itemListElement mapping function */
    if (this.state.routersArr.length > 0) {
      itemListElement = _.map(this.state.routersArr, (routerItem, index) => {
        /* as first route, Home(About) path */
        if (index === 0) {
          return ({
            "@type": "ListItem",
            "position": index + 1,
            "name": "About",
            "item": `${url.origin}/${lang}/`
          })
        } else {
          /* other route */
          return ({
            "@type": "ListItem",
            "position": index + 1,
            "name": _.capitalize(routerItem),
            "item": url.href
          })
        }
      })
    }

    jsonLdBreadcrumb = _.merge(jsonLdBreadcrumb, { "itemListElement": itemListElement });


    return (
      <div className="header">
        <Helmet>
          {this.state.routersArr.length !== 1 ?
            <script type="application/ld+json">
              {`${JSON.stringify(jsonLdBreadcrumb)}`}
            </script> : null
          }
        </Helmet>
        {
          (this.state.routersArr.length >= 3) ?
            <RightMenu routersArr={this.state.routersArr} location={this.props.location} />
            :
            <TopMenu routersArr={this.state.routersArr} location={this.props.location} />
        }
      </div>
    )
  }
}

class TopMenu extends React.Component {
  static defaultProps = {
    routersArr: [""],
    location: {},
  }

  render() {
    return (
      <div className="topMenu">
        <Container className="">
          <Nav className="justify-content-center align-items-center justify-content-lg-around">
            <Col xs={12} sm={2} md={1} lg={2} xl={1}
              className="d-flex justify-content-center justify-content-xl-center pl-xl-0">
              <li className={"navbar-brand"}>
                <Link className="nav-link pl-xl-0" id={"home"} to={"/"}>
                  <img className="headerLogoAnimation" src={logoFrameImgUrl} width={35} alt="Home" />
                </Link>
              </li>
            </Col>
            <Col xs={12} sm={10} md={11} lg={9} xl={7}
              className="d-flex flex-wrap flex-md-nowrap justify-content-between">
              <TopNavItemComponent
                id={"about"}
                to={`/${i18n.language}/`}
                text={i18n.t("header.about")}
                isActive={this.props.routersArr.length === 1}
              />
              <TopNavItemComponent
                id={"portfolio"}
                to={`/${i18n.language}/portfolio`}
                text={i18n.t("header.portfolio")}
                isActive={_.get(this.props.routersArr, 1) === "portfolio"}
              />
              <TopNavItemComponent
                id={"service"}
                to={`/${i18n.language}/service`}
                text={i18n.t("header.service")}
                isActive={_.get(this.props.routersArr, 1) === "service"}
              />
              <TopNavItemComponent
                id={"job"}
                to={`/${i18n.language}/job`}
                text={i18n.t("header.job")}
                isActive={_.get(this.props.routersArr, 1) === "job"}
              />
              <TopNavItemComponent
                id={"contact"}
                to={`/${i18n.language}/contact`}
                text={i18n.t("header.contact")}
                isActive={_.get(this.props.routersArr, 1) === "contact"}
              />
              <LanguageTopNavItemComponent location={this.props.location} />
            </Col>
          </Nav>
        </Container>
      </div>
    )
  }
}

const TopNavItemComponent = ({ id = "", to = "#", text = "", isActive = false }) => (
  <NavItem className="text-uppercase px-2 py-3"
    active={isActive}>
    <Link className="nav-link pt-0 px-0 pb-3" id={id} to={to}>{text}</Link>
  </NavItem>
)

const LanguageTopNavItemComponent = ({ location = {}, }) => {
  let pathname = _.get(location, 'pathname', `/${i18n.language}/`);
  const languageChoices = ["zh", "en"];
  const otherLanguages = _.without(languageChoices, i18n.language);

  return (
    <NavItem className="text-uppercase px-2 py-3 navItemLanguage">
      <Link className="nav-link pt-0 px-0 pb-3 d-inline-block active" to={pathname}>&middot; {i18n.t(`languages.${i18n.language}`)}</Link>
      {otherLanguages.map((lang) =>
        <Link className="nav-link pt-0 px-0 pb-3 d-inline-block ml-3" to={_.replace(pathname, new RegExp(i18n.language, "g"), lang)} key={lang}>
          {i18n.t(`languages.${lang}`)}
        </Link>
      )
      }
    </NavItem>
  );
}

class RightMenu extends React.Component {
  static defaultProps = {
    routersArr: [""],
  }

  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
  }

  toggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  render() {
    return (
      <div className="rightMenu">
        <Navbar light className="navBarContainer align-items-center justify-content-center flex-column flex-nowrap navbar p-0">
          <div className="topLineContainer" >
            <div className="topLine" />
          </div>
          <Button outline color="secondary"
            className={`togglerButton ${(this.state.isMenuOpen) ? "openToggle" : "closeToggle"} d-flex align-items-center justify-content-center p-0 m-4`}
            onClick={() => this.toggleMenu()}>
            <div className="triangleContainer d-flex align-items-center justify-content-center">
              <span className="triangle" />
            </div>
          </Button>
          {/* -- nav bar -- */}
          <Collapse isOpen={this.state.isMenuOpen} className="navigateClickable" navbar>
            <Nav className="justify-content-center align-items-center p-2" vertical>
              <LeftNavItemComponent
                id={"about"}
                to={`/${i18n.language}/`}
                text={i18n.t("header.about")}
                isActive={this.props.routersArr.length === 1}
              />
              <LeftNavItemComponent
                id={"portfolio"}
                to={`/${i18n.language}/portfolio`}
                text={i18n.t("header.portfolio")}
                isActive={_.get(this.props.routersArr, 1) === "portfolio"}
              />
              <LeftNavItemComponent
                id={"service"}
                to={`/${i18n.language}/service`}
                text={i18n.t("header.service")}
                isActive={_.get(this.props.routersArr, 1) === "service"}
              />
              <LeftNavItemComponent
                id={"job"}
                to={`/${i18n.language}/job`}
                text={i18n.t("header.job")}
                isActive={_.get(this.props.routersArr, 1) === "job"}
              />
              <LeftNavItemComponent
                id={"contact"}
                to={`/${i18n.language}/contact`}
                text={i18n.t("header.contact")}
                isActive={_.get(this.props.routersArr, 1) === "contact"}
              />
              <LanguageLeftNavItemComponent location={this.props.location} />
            </Nav>
          </Collapse>
          {/* -- breadcrumb indicator -- */}
          <Collapse isOpen={!this.state.isMenuOpen} className="breadcrumbInfo justify-content-center" navbar onClick={() => this.toggleMenu()}>
            <Nav vertical className="justify-content-around">
              <NavItem className="text-uppercase text-center">
                <NavLink href={"#"} className="p-0">Menu</NavLink>
              </NavItem>
              {/* <NavItem className="text-uppercase text-center">
              <NavLink href={"#"} className="currentRouteName">{this.props.routersArr[0]}
              </NavLink>
            </NavItem> */}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

const LeftNavItemComponent = ({ id = "", to = "#", text = "", isActive = false }) => (
  <NavItem className="text-uppercase mb-2"
    active={isActive}>
    <Link className="nav-link px-0" id={id} to={to}>{text}</Link>
  </NavItem>
)

const LanguageLeftNavItemComponent = ({ location = {}, }) => {
  let pathname = _.get(location, 'pathname', `/${i18n.language}/`);
  const languageChoices = ["zh", "en"];
  const otherLanguages = _.without(languageChoices, i18n.language);

  return (
    <NavItem className="text-uppercase px-2 py-3 navItemLanguage">
      <Link className="nav-link pt-0 px-0 pb-1 active" to={pathname}>&middot; {i18n.t(`languages.${i18n.language}`)}</Link>
      {otherLanguages.map((lang) =>
        <Link className="nav-link pt-0 px-0 pb-1" to={_.replace(pathname, new RegExp(i18n.language, "g"), lang)} key={lang}>
          <span className="text-white">&middot;</span> {i18n.t(`languages.${lang}`)}
        </Link>
      )
      }
    </NavItem>
  );
}

// const EachPortfolioBreadCrumb = ({ portfolioName = "" }) => (
//   <div className="portfolioBreadCrumb text-uppercase active">
//     <p className="nav-link mb-0" id={portfolioName}>{portfolioName}</p>
//   </div>
// )

export default withRouter(Header);