import React from "react";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import i18n from '../../i18next';
import { Container, Row } from "reactstrap";
import heroImageUrl from "../../images/portfolioMoonlightDog/moonlightdogHero.jpg";
import HeroContainer from "./commons/HeroContainer";
import IntroductionSection from "./commons/IntroductionSection";
import moonlightdogLogo from "../../images/portfolioMoonlightDog/logo.png";
import { DesktopView, MobileiXBlackView } from "./commons/WorkGallery";
import { ConceptKeyPointsListSection } from "./commons/ConceptSection";
import NextIndicatorSection from "./commons/NextIndicatorSection";

/* screenshots */
import mobileScreenshotURL01 from "../../images/portfolioMoonlightDog/mobileScreenshot01.jpg";
import mobileScreenshotURL02 from "../../images/portfolioMoonlightDog/mobileScreenshot02.jpg";
import mobileScreenshotURL03 from "../../images/portfolioMoonlightDog/mobileScreenshot03.jpg";

import desktopScreenshotURL01 from "../../images/portfolioMoonlightDog/desktopScreenshot01.jpg";
import desktopScreenshotURL02 from "../../images/portfolioMoonlightDog/desktopScreenshot02.jpg";
import desktopScreenshotURL03 from "../../images/portfolioMoonlightDog/desktopScreenshot03.jpg";
import desktopScreenshotURL04 from "../../images/portfolioMoonlightDog/desktopScreenshot04.jpg";
import desktopScreenshotURL05 from "../../images/portfolioMoonlightDog/desktopScreenshot05.jpg";
import desktopScreenshotURL06 from "../../images/portfolioMoonlightDog/desktopScreenshot06.jpg";

export default class Moonlightdog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { windowScrollY: 0 };
  }

  componentDidMount() {
    /* inital scoll settting to be on the top */
    window.scrollTo(0, 0);

    window.onscroll = () => {
      /* need to set scroll in parent prevent glitch */
      this.setState({ windowScrollY: window.scrollY });
    }
  }

  render() {
    const url = new URL(window.location.href);
    const helmetContent = i18n.t("MoonlightdogPortfolio.introduction");
    const helmetTitle = `Moonlight Dog Cafe - ${i18n.t("jsonLdOrganization.shortCompName")}`;
    return (
      <div className="specificPortfolio">
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetContent} />
          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:url" content={`${url.href}`} />
          <meta property="og:image" content={desktopScreenshotURL01} />
          <meta property="og:description" content={helmetContent} />
          {/* canonical url, see: https://support.google.com/webmasters/answer/139066 */}
          <link rel="canonical" href={`${url.href}`} />
        </Helmet>
        <HeroContainer
          heroImageUrl={heroImageUrl}
          title="Moonlight Dog Cafe"
        />
        <Container fluid={true} className="mainSections">
          <div className="pt-2 pt-md-3 pt-lg-4">
            <IntroductionSection
              title="Moonlight Dog Cafe"
              squareImgUrl={moonlightdogLogo}
              description={i18n.t("MoonlightdogPortfolio.introduction")}
              yearMonthText="June 2017"
              windowScrollY={this.state.windowScrollY}
              externalLinkURL={"https://www.moonlightdogcafe.com/"}
            />
            <Container>
              <Row className="showCaseSection p-1 p-sm-2 px-lg-4 py-md-3">
                <DesktopView
                  screenshotUrls={[desktopScreenshotURL01, desktopScreenshotURL02, desktopScreenshotURL03, desktopScreenshotURL04, desktopScreenshotURL05, desktopScreenshotURL06]}
                />
                <MobileiXBlackView
                  screenshotUrls={[mobileScreenshotURL01, mobileScreenshotURL02, mobileScreenshotURL03]}
                />
                <ConceptKeyPointsListSection
                  keyPoints={i18n.t("MoonlightdogPortfolio.keypoints", { returnObjects: true })}
                />
              </Row>
            </Container>
            <NextIndicatorSection
              linkTo={"/portfolio/matchfit"}
              linkText={"MatchFit"}
              windowScrollY={this.state.windowScrollY}
            />
          </div>
        </Container>
      </div>
    )
  }
}