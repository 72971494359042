import React from "react";
import { Row, Col } from "reactstrap";
import arrowDownSVG from "../../../images/arrowDown.svg";
import { Link } from 'react-router-dom';
import i18n from "../../../i18next";

export default class HeroContainer extends React.Component {
  static defaultProps = {
    linkTo: "#",
    linkText: "",
    windowScrollY: 0,
  }

  /* mounted status, to determine whether to setState */
  _mounted = false;
  /* refernce to text for opacity */
  textRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      timestamp: 0
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.setState({ timestamp: "?ts=" + Math.floor(Date.now() / 1000) });
    this.loopingForArrowImage();

    /* initialization
    (user may reload, scroll position initailly inside this division) */
    this.onScrollTransformCaculate();
  }

  componentDidUpdate() {
    this.onScrollTransformCaculate();
    this.isShowContentDetection();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  loopingForArrowImage() {
    setInterval(() => {
      if (this._mounted) {
        this.setState({ timestamp: "?ts=" + Math.floor(Date.now() / 1000) })
      }
    }, 2500);
  }

  onScrollTransformCaculate = () => {
    const windowScrollYPosition = this.props.windowScrollY;

    /* get whole html's height. all browsers supports */
    const wholeDocBody = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);

    const scrollTransfromTarget = this.textRef.current;
    if (scrollTransfromTarget) {
      /* scrollFixedOffset caculation:
                    scrollFixedDivistion from its top toward top of doc. */
      const scrollFixedOffset = wholeDocBody - window.innerHeight - scrollTransfromTarget.getBoundingClientRect().height;

      /* once reach the scrollFixedOffset view area, setup translateY */
      if (windowScrollYPosition > scrollFixedOffset && this._mounted) {
        /* caculation the scroll position between scrollFixedOffset */
        let changePosition = windowScrollYPosition - scrollFixedOffset;
        /* cover to percentage */
        changePosition = (1 - changePosition / scrollTransfromTarget.getBoundingClientRect().height) * 100;
        scrollTransfromTarget.style.transform = `translateY(${changePosition}%)`;
      }
    }
  }

  isShowContentDetection = () => {
    const textRefCurr = this.textRef.current;
    const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (textRefCurr) {
      if (viewPortHeight - textRefCurr.getBoundingClientRect().height * 1 / 3 > textRefCurr.getBoundingClientRect().top) {
        textRefCurr.classList.add("isShowText");
      } else {
        textRefCurr.classList.remove("isShowText");
      }
    }
  }

  render() {
    return (
      <Row className="nextIndicatorSection">
        <Col xs="12" className="p-0 mt-5 overflowHidden">
          <div id="scrollTransfromTarget" ref={this.textRef}>
            <Link
              to={this.props.linkTo} id={this.props.linkTo}
              className="cover textContentOpacity align-items-center d-flex flex-column justify-content-center">
              <h5 className="text-uppercase my-4 nextText">{i18n.t('portfolio.collections.next')}</h5>
              <h4 className="my-4 portflioText">{this.props.linkText}</h4>
              <img src={arrowDownSVG + this.state.timestamp} alt={"arrowDown"} className="arrowDown my-4 img-fluid" />
            </Link>
            <div className="backgroundConver" />
          </div>
        </Col>
      </Row>
    )
  }
}