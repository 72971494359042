import React from "react";
import { Row, Col } from "reactstrap";

const ThematicBanner = ({ title = "", description = "", }) => (
  <Row className={"thematicBanner my-3 my-lg-5 p-2 p-sm-3 p-lg-4 p-xl-5 text-center"}>
    <Col xs={{ size: 12 }} className="align-items-center d-sm-flex justify-content-around">
      {/* <h3 className="m-0">{title}</h3> */}
      <p className="m-0">{description}</p>
    </Col>
  </Row>
);

export default ThematicBanner;