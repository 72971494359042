import React from "react";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import i18n from "../../i18next";
import { Container, Row } from "reactstrap";
import heroImageUrl from "../../images/portfolioTtap/heroBanner.jpg";
import HeroContainer from "./commons/HeroContainer";
import IntroductionSection from "./commons/IntroductionSection";
import TtapLogo from "../../images/portfolioTtap/logo.png";
import { DesktopView, MobileiXBlackView } from "./commons/WorkGallery";
import { ConceptKeyPointsListSection } from "./commons/ConceptSection";
import NextIndicatorSection from "./commons/NextIndicatorSection";

/* screenshots */
import desktopScreenshotURL01 from "../../images/portfolioTtap/desktopScreenshot01.jpg";
import desktopScreenshotURL02 from "../../images/portfolioTtap/desktopScreenshot02.jpg";
import desktopScreenshotURL03 from "../../images/portfolioTtap/desktopScreenshot03.jpg";
import desktopScreenshotURL04 from "../../images/portfolioTtap/desktopScreenshot04.jpg";
import desktopScreenshotURL05 from "../../images/portfolioTtap/desktopScreenshot05.jpg";
import desktopScreenshotURL06 from "../../images/portfolioTtap/desktopScreenshot06.jpg";
import desktopScreenshotURL07 from "../../images/portfolioTtap/desktopScreenshot07.jpg";
import desktopScreenshotURL08 from "../../images/portfolioTtap/desktopScreenshot08.jpg";
import desktopScreenshotURL09 from "../../images/portfolioTtap/desktopScreenshot09.jpg";
import desktopScreenshotURL10 from "../../images/portfolioTtap/desktopScreenshot10.jpg";
import desktopScreenshotURL11 from "../../images/portfolioTtap/desktopScreenshot11.jpg";

import mobileScreenshotURL01 from "../../images/portfolioTtap/mobileScreenshot01.jpg";
import mobileScreenshotURL02 from "../../images/portfolioTtap/mobileScreenshot02.jpg";
import mobileScreenshotURL03 from "../../images/portfolioTtap/mobileScreenshot03.jpg";
import mobileScreenshotURL04 from "../../images/portfolioTtap/mobileScreenshot04.jpg";
import mobileScreenshotURL05 from "../../images/portfolioTtap/mobileScreenshot05.jpg";
import mobileScreenshotURL06 from "../../images/portfolioTtap/mobileScreenshot06.jpg";
import mobileScreenshotURL07 from "../../images/portfolioTtap/mobileScreenshot07.jpg";
import mobileScreenshotURL08 from "../../images/portfolioTtap/mobileScreenshot08.jpg";
import mobileScreenshotURL09 from "../../images/portfolioTtap/mobileScreenshot09.jpg";

export default class Ttap extends React.Component {
  constructor(props) {
    super(props);
    this.state = { windowScrollY: 0 };
  }

  componentDidMount() {
    /* inital scoll settting to be on the top */
    window.scrollTo(0, 0);

    window.onscroll = () => {
      /* need to set scroll in parent prevent glitch */
      this.setState({ windowScrollY: window.scrollY });
    }
  }

  render() {
    const url = new URL(window.location.href);
    const helmetContent = i18n.t("ttapPortfolio.introduction");
    const helmetTitle = `${i18n.t("ttapPortfolio.abbriTitle")} - ${i18n.t("jsonLdOrganization.shortCompName")}`;
    return (
      <div className="specificPortfolio">
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetContent} />
          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:url" content={`${url.href}`} />
          <meta property="og:image" content={desktopScreenshotURL01} />
          <meta property="og:description" content={helmetContent} />
          {/* canonical url, see: https://support.google.com/webmasters/answer/139066 */}
          <link rel="canonical" href={`${url.href}`} />
        </Helmet>
        <HeroContainer
          heroImageUrl={heroImageUrl}
          title={i18n.t("ttapPortfolio.title")}
        />
        <Container fluid={true} className="mainSections">
          <div className="pt-2 pt-md-3 pt-lg-4">
            <IntroductionSection
              title={i18n.t("ttapPortfolio.abbriTitle")}
              squareImgUrl={TtapLogo}
              description={i18n.t("ttapPortfolio.introduction")}
              yearMonthText="February 2019"
              windowScrollY={this.state.windowScrollY}
              externalLinkURL={"https://ttap.com.tw/"}
            />
            <Container>
              <Row className="showCaseSection p-1 p-sm-2 px-lg-4 py-md-3">
                <DesktopView
                  screenshotUrls={[desktopScreenshotURL01, desktopScreenshotURL02, desktopScreenshotURL03, desktopScreenshotURL04, desktopScreenshotURL05, desktopScreenshotURL06, desktopScreenshotURL07, desktopScreenshotURL08, desktopScreenshotURL09, desktopScreenshotURL10, desktopScreenshotURL11]}
                />
                <MobileiXBlackView
                  screenshotUrls={[mobileScreenshotURL01, mobileScreenshotURL02, mobileScreenshotURL03, mobileScreenshotURL04, mobileScreenshotURL05, mobileScreenshotURL06, mobileScreenshotURL07, mobileScreenshotURL08, mobileScreenshotURL09]}
                />
                <ConceptKeyPointsListSection
                  keyPoints={i18n.t("ttapPortfolio.keypoints", { returnObjects: true })}
                />
              </Row>
            </Container>
            <NextIndicatorSection
              linkTo={"/portfolio/moonlight-dog-cafe"}
              linkText={"Moonlight Dog Cafe"}
              windowScrollY={this.state.windowScrollY}
            />
          </div>
        </Container>
      </div>
    )
  }
}