import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import logoFrameImgUrl from "../../../images/logoFrame.png";
import arrowDownSVG from "../../../images/arrowDown.svg";
import i18n from "../../../i18next";

export default class HeroContainer extends React.Component {
  static defaultProps = {
    heroImageUrl: "",
    title: ""
  }

  constructor(props) {
    super(props);
    this.state = { timestamp: 0 };
  }

  /* mounted status, to determine whether to setState */
  mounted = false;

  componentDidMount() {
    this._mounted = true;
    this.setState({ timestamp: "?ts=" + Math.floor(Date.now() / 1000) });
    this.loopingForArrowImage();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  loopingForArrowImage() {
    setInterval(() => {
      if (this._mounted) {
        this.setState({ timestamp: "?ts=" + Math.floor(Date.now() / 1000) })
      }
    }, 2500);
  }

  render() {
    return (
      <Container fluid={true} className="heroContainer">
        <Row>
          <Link id={"home"} to={"/"} className="topLeftLogo m-4 m-md-3">
            <img src={logoFrameImgUrl} alt="Gopomo" className="img-fluid" />
          </Link>
        </Row>
        <div className="heroContent d-flex flex-column justify-content-center">
          <Row className="justify-content-center">
            <Col xs={"12"} className="portfolioTextContainer">
              <h1 className="text-center transiformTrasnlateYAni">
                {i18n.t("portfolio.title")}
              </h1>
            </Col>
            <Col sm={{ size: 7 }} md={{ size: 6 }} className="text-center imgWrapper">
              <img className="img-fluid centerHeroImage" src={this.props.heroImageUrl} alt={this.props.heroImageUrl} />
            </Col>
            <Col xs={"12"} className="portfolioNameContainer">
              <h2 className="text-center my-3 my-sm-4 my-lg-5 transiformTrasnlateYAni">
                {this.props.title}
              </h2>
            </Col>
            {
              (window.navigator.userAgent.indexOf('Trident/') > 0 || window.navigator.userAgent.indexOf('MSIE ') > 0) ?
                null
                :
                <Col xs={"12"} className="text-center">
                  <img src={arrowDownSVG + this.state.timestamp} alt={"arrowDown"} className="arrowDown img-fluid" />
                </Col>
            }
          </Row>
        </div>
      </Container>
    )
  }
}