import React from "react";
import _ from "lodash";
import i18next from "../../i18next";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import vancouverCityImg from "../../images/home/vancouer.png";
import torontoCityImg from "../../images/home/toronto.png";
import taipeiCityImg from "../../images/home/taipei.png";
import washingtonCityImg from "../../images/home/washington.png";
import hongkongCityImg from "../../images/home/hongkong.png";

import mammographyBg from "../../images/portfolioMammography/heroBanner.jpg";
import ttapBg from "../../images/portfolioTtap/heroBanner.jpg";
import moonlightDogBg from "../../images/portfolioMoonlightDog/moonlightdogHero.jpg";
import matchfitBg from "../../images/portfolioMatchfit/matchfitHero.jpg";
import lunicaBg from "../../images/portfolioLunica/lunicaHero.jpg";
import compalBg from "../../images/portfolioCompal/compalHero.jpg";
import minefillServiceBg from "../../images/portfolioMinefillServices/minefillServicesHero.jpg";
import moonspecsBg from "../../images/portfolioMoonspecs/moonspecsHero.jpg";
import heroSpoonBg from "../../images/portfolioHeroSpoon/heroSpoonHero.jpg";
import iRetailARBg from "../../images/portfolioiRetailAR/iRetailARHero.jpg";

const portfolioCitiesList = [
  {
    portfolioName: "iTaitungMammo",
    heroImage: mammographyBg,
    cityName: i18next.t("home.profileItems.tiatung"),
    cityImage: taipeiCityImg,
    relativeURL: "/portfolio/mammography",
  },
  {
    portfolioName: "TTAP Co., Ltd",
    heroImage: ttapBg,
    cityName: i18next.t("home.profileItems.tiatung"),
    cityImage: taipeiCityImg,
    relativeURL: "/portfolio/ttap",
  },
  {
    portfolioName: "Moonlight Dog Cafe",
    heroImage: moonlightDogBg,
    cityName: i18next.t("home.profileItems.vancouver"),
    cityImage: vancouverCityImg,
    relativeURL: "/portfolio/moonlight-dog-cafe",
  },
  {
    portfolioName: "MatchFit",
    heroImage: matchfitBg,
    cityName: i18next.t("home.profileItems.toronto"),
    cityImage: torontoCityImg,
    relativeURL: "/portfolio/matchfit",
  },
  {
    portfolioName: "Lunica",
    heroImage: lunicaBg,
    cityName: i18next.t("home.profileItems.taipei"),
    cityImage: taipeiCityImg,
    relativeURL: "/portfolio/lunica",
  },
  {
    portfolioName: "Compal",
    heroImage: compalBg,
    cityName: i18next.t("home.profileItems.taipei"),
    cityImage: taipeiCityImg,
    relativeURL: "/portfolio/compal",
  },
  {
    portfolioName: "Minefill Service",
    heroImage: minefillServiceBg,
    cityName: i18next.t("home.profileItems.washington"),
    cityImage: washingtonCityImg,
    relativeURL: "/portfolio/minefill-service",
  },
  {
    portfolioName: "Moonspecs",
    heroImage: moonspecsBg,
    cityName: i18next.t("home.profileItems.hongkong"),
    cityImage: hongkongCityImg,
    relativeURL: "/portfolio/moonspecs",
  },
  {
    portfolioName: "HeroSpoon",
    heroImage: heroSpoonBg,
    cityName: i18next.t("home.profileItems.toronto"),
    cityImage: torontoCityImg,
    relativeURL: "/portfolio/herospoon",
  },
  {
    portfolioName: "iRetail AR",
    heroImage: iRetailARBg,
    cityName: i18next.t("home.profileItems.taipei"),
    cityImage: taipeiCityImg,
    relativeURL: "/portfolio/iRetailAR",
  },
]

const PortfolioCitieItem = ({ portfolioObj = {}, keyIndex = 0 }) => (
  <Link
    className={`portfolioContainer d-inline-block  ${(0 === keyIndex) ? "portfolioShow" : "portfolioHide"}`}
    to={_.get(portfolioObj, "relativeURL")}
    id={_.camelCase(_.get(portfolioObj, "portfolioName"))}>
    <div className="heroImgContainer align-items-center d-flex justify-content-center">
      <div className="heroImgWrapper">
        <img className="heroImage" src={_.get(portfolioObj, "heroImage")} alt={_.get(portfolioObj, "portfolioName")} />
      </div>
    </div>

    <div className="contentContainer position-absolute align-items-center d-flex flex-column justify-content-center">
      <div className="contentsWrapper p-3 align-items-center d-flex flex-column justify-content-center">
        <img src={_.get(portfolioObj, "cityImage")} alt={_.capitalize(_.get(portfolioObj, "cityName"))} className="cityImage img-fluid" />
        <h3 className="text-uppercase">{_.get(portfolioObj, "cityName")}</h3>
        <h4>{_.get(portfolioObj, "portfolioName")}</h4>
      </div>
    </div>
  </Link>
)



export default class PortfolioCitiesSection extends React.Component {
  refPortfolioContainer = React.createRef();
  indexCurrent = 0;
  isPauseTimeInterval = false;

  /* reference timeout */
  leftTimeoutRef = null;
  rightTimeoutRef = null;
  autoTimeRef = null;

  componentDidMount() {
    this.autoTimeRef = setInterval(() => {
      if (!this.isPauseTimeInterval) {
        this.onArrowRightClick();
      }
    }, 5000);
  }

  componentWillUnmount() {
    if (this.leftTimeoutRef) {
      clearTimeout(this.leftTimeoutRef);
      this.leftTimeoutRef = null;
    }
    if (this.rightTimeoutRef) {
      clearTimeout(this.rightTimeoutRef);
      this.rightTimeoutRef = null;
    }
    if (this.autoTimeRef) {
      clearTimeout(this.autoTimeRef);
      this.autoTimeRef = null;
    }
  }


  onArrowLeftClick = () => {
    const childLength = this.refPortfolioContainer.current.childElementCount;
    this.indexCurrent = Math.abs((this.indexCurrent + childLength) % childLength);
    const currentShowupChild = this.refPortfolioContainer.current.childNodes[this.indexCurrent];
    const nextShowupChild = this.refPortfolioContainer.current.childNodes[(this.indexCurrent - 1 + childLength) % childLength];

    /* current child hiding */
    currentShowupChild.classList.add("hidingPortfolio");
    /* next child showing */
    nextShowupChild.classList.add("showingPortfolio");
    /* after animation */
    this.leftTimeoutRef = setTimeout(() => {
      currentShowupChild.classList.remove("hidingPortfolio");
      currentShowupChild.classList.remove("portfolioShow");
      currentShowupChild.classList.add("portfolioHide");

      nextShowupChild.classList.remove("showingPortfolio");
      nextShowupChild.classList.remove("portfolioHide");
      nextShowupChild.classList.add("portfolioShow");
    }, 500);

    this.indexCurrent--;
  }

  onArrowRightClick = () => {
    const childLength = this.refPortfolioContainer.current.childElementCount;
    this.indexCurrent = this.indexCurrent % childLength;

    const currentShowupChild = this.refPortfolioContainer.current.childNodes[this.indexCurrent];
    const nextShowupChild = this.refPortfolioContainer.current.childNodes[(this.indexCurrent + 1) % childLength];

    /* current child hiding */
    currentShowupChild.classList.add("hidingPortfolio");
    /* next child showing */
    nextShowupChild.classList.add("showingPortfolio");
    /* after animation */
    this.rightTimeoutRef = setTimeout(() => {
      currentShowupChild.classList.remove("hidingPortfolio");
      currentShowupChild.classList.remove("portfolioShow");
      currentShowupChild.classList.add("portfolioHide");

      nextShowupChild.classList.remove("showingPortfolio");
      nextShowupChild.classList.remove("portfolioHide");
      nextShowupChild.classList.add("portfolioShow");
    }, 500);

    this.indexCurrent++;
  }


  render() {
    return (
      <div className="portfolioCitiesSection my-5"
        onMouseEnter={() => { this.isPauseTimeInterval = true }}
        onMouseLeave={() => { this.isPauseTimeInterval = false }}
      >
        <Row className="justify-content-center">
          <Col className="d-flex align-items-start align-items-sm-center justify-content-center" xs="1">
            <div className="triangleLeft" onClick={() => this.onArrowLeftClick()} />
          </Col>
          <Col className="align-content-center d-flex justify-content-center" xs="10" sm="10" md="6">
            <div className="portfoliosCitiesWrapper position-relative" ref={this.refPortfolioContainer}>
              {_.map(portfolioCitiesList, (portfolioItem, index) => (
                <PortfolioCitieItem
                  portfolioObj={portfolioItem}
                  key={index.toString()}
                  keyIndex={index}
                />
              ))}
            </div>
          </Col>
          <Col className="d-flex align-items-start align-items-sm-center justify-content-center" xs="1">
            <div className="triangleLeft rotateToRight" onClick={() => this.onArrowRightClick()} />
          </Col>
        </Row>
      </div>
    )
  }
}