import React from "react";
import { Container, Row, Col } from "reactstrap";
import i18n from "../../../i18next";

export default class IntroductionSection extends React.Component {
  static defaultProps = {
    title: "",
    description: "",
    squareImgUrl: "",
    rectangleImgUrl: "",
    yearMonthText: "",
    windowScrollY: 0,
    externalLinkURL: null
  }

  constructor(props) {
    super(props);
    this.state = {
      isShowDescription: false,
      isShowLogo: false,
      isShowSubTitle: false,
      isShowTitle: false,
    };
  }

  /* reference for specific title */
  subTitleRef = React.createRef(); /* `our Work` */
  /* reference for logo image */
  logoRef = React.createRef();
  /* reference for portfolio title */
  titleRef = React.createRef(); /* the portfolio name */

  componentDidMount() {
    this.descriptionSlidingAnimation();
  }

  componentWillReceiveProps() {
    this.descriptionSlidingAnimation();
    this.logoSlidingAnimation();
    this.showSubTitle();
    this.showTitle();
  }

  componentWillUnmount() {
  }

  descriptionSlidingAnimation = () => {
    const windowScrollYPosition = this.props.windowScrollY;
    const descriptionContainer = document.getElementById("descriptionContainer");
    /* getBoundingClientRect(), return value `relative to the top-left of the viewport.`ref:https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect */
    if (descriptionContainer) {
      if (windowScrollYPosition >= descriptionContainer.getBoundingClientRect().top - descriptionContainer.getBoundingClientRect().height) {
        this.setState({ isShowDescription: true });
      } else {
        this.setState({ isShowDescription: false });
      }
    }
  }

  logoSlidingAnimation = () => {
    const imageContainer = this.logoRef.current;
    /* getBoundingClientRect(), return value `relative to the top-left of the viewport.`ref:https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect */
    if (imageContainer) {
      if (window.innerHeight > imageContainer.getBoundingClientRect().top + imageContainer.getBoundingClientRect().height) {
        this.setState({ isShowLogo: true });
      } else {
        this.setState({ isShowLogo: false });
      }
    }
  }

  showSubTitle = () => {
    const subTitleContainer = this.subTitleRef.current;
    if (subTitleContainer) {
      if (window.innerHeight > subTitleContainer.getBoundingClientRect().top + subTitleContainer.getBoundingClientRect().height) {
        /* subTitleContainer's bottom relative to top is lower then view-port */
        this.setState({ isShowSubTitle: true });
      }
    }
  }

  showTitle = () => {
    const titleRefContainer = this.titleRef.current;
    if (titleRefContainer) {
      if (window.innerHeight > titleRefContainer.getBoundingClientRect().top + titleRefContainer.getBoundingClientRect().height) {
        /* titleRefContainer's bottom relative to top is lower then view-port */
        this.setState({ isShowTitle: true });
      }
    }
  }

  render() {
    return (
      <Container className="introductionSection">
        <Row className="align-items-center justify-content-center my-1 my-sm-2 my-md-3 my-lg-4">
          <Col xs="10" sm="7" md="6" lg="5" xl="5" className="text-center text-sm-left">
            <h4 ref={this.subTitleRef}
              className={`${(this.state.isShowSubTitle) ? "clipPathAniIn" : ""}`}>
              {i18n.t('portfolio.collections.ourWork')}
            </h4>
            <h2 ref={this.titleRef}
              className={`${(this.state.isShowTitle) ? "clipPathAniIn" : ""}`}>
              {this.props.title}
              {/* {_.split(this.props.title, "").map((character, index) =>
                (<span key={index.toString()}>{character}</span>)
              )} */}
              {(this.props.externalLinkURL) ?
                <span className="linkIcon">
                  <a href={this.props.externalLinkURL} target={"_blank"}>
                    <svg height="18px" version="1.1" viewBox=
                      "0 0 17 18" width="17px" xmlns=
                      "http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd" type="MSPage"
                        stroke="none" strokeWidth="1">
                        <g fill="#FFFFFF" type="MSArtboardGroup"
                          transform="translate(-71.000000, -253.000000)">
                          <g id="Group" type="MSLayerGroup" transform=
                            "translate(-49.000000, 37.126849)">
                            <g id="share" type="MSShapeGroup"
                              transform="translate(77.000000, 215.000000) ">
                              <path d=
                                "M56.7027373,7.3239881 C55.9905985,7.3239881 55.367477,7.59104016 54.9223903,8.03612692 L48.6021583,4.38641548 C48.6911756,4.20838077 48.6911756,3.94132871 48.6911756,3.76329401 C48.6911756,2.24999902 47.53395,1.09277344 46.020655,1.09277344 C44.50736,1.09277344 43.3501345,2.24999902 43.3501345,3.76329401 C43.3501345,5.276589 44.50736,6.43381458 46.020655,6.43381458 C46.7327939,6.43381458 47.3559153,6.16676252 47.8010021,5.72167576 L54.1212341,9.37138721 C54.0322167,9.54942191 54.0322167,9.81647397 54.0322167,9.99450868 C54.0322167,10.1725434 54.0322167,10.4395954 54.1212341,10.6176301 L47.8010021,14.3563589 C47.3559153,13.9112722 46.7327939,13.6442201 46.020655,13.6442201 C44.5963774,13.6442201 43.4391518,14.8014457 43.4391518,16.2257233 C43.4391518,17.650001 44.5963774,18.8072266 46.020655,18.8072266 C47.4449327,18.8072266 48.6021583,17.650001 48.6021583,16.2257233 C48.6021583,16.0476886 48.6021583,15.8696539 48.5131409,15.6026019 L54.8333729,11.8638731 C55.2784597,12.3089598 55.9015811,12.5760119 56.61372,12.5760119 C58.127015,12.5760119 59.2842405,11.4187863 59.2842405,9.90549132 C59.2842405,8.39219633 58.2160323,7.3239881 56.7027373,7.3239881 L56.7027373,7.3239881 L56.7027373,7.3239881 Z">
                              </path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </span> :
                null
              }
            </h2>
          </Col>
          <Col xs="12" sm="3" md="3" lg="2" xl="2" className="d-flex align-items-center justify-content-center">
            <img ref={this.logoRef} src={this.props.squareImgUrl} alt={this.props.title} className={`img-fluid logoImage ${(this.state.isShowLogo) ? "showLogo" : ""}`} />
          </Col>
        </Row>
        <Row className="my-1 my-sm-2 my-md-3 my-lg-4 overflowHidden justify-content-center">
          <Col xs="10" sm="10" md="9" lg="7" xl="7"
            className={`d-flex flex-column justify-content-between ${(this.state.isShowDescription) ? "show" : "hide"}`}
            id="descriptionContainer">
            <p className="text-justify">{this.props.description}</p>
            <small className="text-right py-2 py-xl-5"><i>{this.props.yearMonthText}</i></small>
          </Col>
          {/* <Col xs="12" sm="6" md="5"
            className={`text-center ${(this.state.isShowImage) ? "show" : "hide"}`}
            id="imageContainer">
            <img src={this.props.rectangleImgUrl} alt={this.props.title} className="img-fluid" />
          </Col> */}
        </Row>
      </Container>
    )
  }
}