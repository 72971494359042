import React from 'react';
import { Container, Row, Col } from "reactstrap";

import logoIcon from "../images/landing/logo-icon.svg";
import G_Icon from "../images/landing/G.svg";
import P_Icon from "../images/landing/P.svg";
import M_Icon from "../images/landing/M.svg";
import O_Icon from "../images/landing/O.svg";
import O_grey_Icon from "../images/landing/O-grey.svg";
import O_white_Icon from "../images/landing/O-white.svg";
import O_yellow_Icon from "../images/landing/O-yellow.svg";
import p2Icon from "../images/landing/p2.svg";
import p_outlineIcon from "../images/landing/p_outline.svg";
import gogIcon from "../images/landing/gog.svg";
import gowIcon from "../images/landing/gow.svg";
import g1Icon from "../images/landing/g1.svg";
import g2Icon from "../images/landing/g2.svg";
import m1Icon from "../images/landing/m1.svg";
import m2Icon from "../images/landing/m2.svg";
import m3Icon from "../images/landing/m3.svg";
import m4Icon from "../images/landing/m4.svg";
import slineIcon from "../images/landing/sline.svg";


const Landing = () => (
  <Container fluid={true} className="landing">
    <Row noGutters={true}>
      <Col className="landingContainer justify-content-center d-flex">
        <div className="htmlNoPages">
          <img src={logoIcon} className="gwd-img-19vn gwd-gen-1yrygwdanimation" id="logo" alt={"Logo"} />
          <div className="gwd-div-9jje" data-gwd-group="gopomo_text">
            <img alt={"gopomoLetter"} src={G_Icon} className="gwd-img-109u gwd-grp-pkem" data-gwd-grp-id="G_1" />
            <img alt={"gopomoLetter"} src={P_Icon} className="gwd-img-9tgm gwd-grp-pkem" data-gwd-grp-id="P_1" />
            <img alt={"gopomoLetter"} src={p2Icon} className="gwd-grp-pkem gwd-img-1m6h gwd-gen-gz67gwdanimation" data-gwd-grp-id="p2" />
            <img alt={"gopomoLetter"} src={O_grey_Icon} className="gwd-grp-pkem gwd-img-uzew gwd-gen-1hcqgwdanimation" data-gwd-grp-id="pg1" />
            <img alt={"gopomoLetter"} src={O_grey_Icon} className="gwd-grp-pkem gwd-img-jfk5 gwd-gen-19ingwdanimation" data-gwd-grp-id="pg2" />
            <img alt={"gopomoLetter"} src={O_grey_Icon} className="gwd-grp-pkem gwd-img-1m8s gwd-gen-nea1gwdanimation" data-gwd-grp-id="pg3" />
            <img alt={"gopomoLetter"} src={O_white_Icon} className="gwd-grp-pkem gwd-img-uet3 gwd-gen-1huzgwdanimation" data-gwd-grp-id="pw" />
            <img alt={"gopomoLetter"} src={p_outlineIcon} className="gwd-grp-pkem gwd-img-c5zs" data-gwd-grp-id="p_outline" />
            <img alt={"gopomoLetter"} src={gogIcon} className="gwd-grp-pkem gwd-img-1w7q gwd-gen-um6qgwdanimation" data-gwd-grp-id="gog" />
            <img alt={"gopomoLetter"} src={gogIcon} className="gwd-grp-pkem gwd-img-1n9q gwd-gen-wpuegwdanimation" data-gwd-grp-id="gog2" />
            <img alt={"gopomoLetter"} src={gowIcon} className="gwd-grp-pkem gwd-img-1t00 gwd-gen-1xpfgwdanimation" data-gwd-grp-id="gow" />
            <img alt={"gopomoLetter"} src={g1Icon} className="gwd-grp-pkem gwd-img-12m7 gwd-gen-yfkqgwdanimation" data-gwd-grp-id="g1" />
            <img alt={"gopomoLetter"} src={g2Icon} className="gwd-grp-pkem gwd-img-166v gwd-gen-188ogwdanimation" data-gwd-grp-id="g2" />
            <div className="gwd-div-1m38 gwd-grp-pkem" data-gwd-grp-id="divO1">
              <img alt={"gopomoLetter"} src={O_Icon} className="gwd-grp-pkem gwd-img-atmk gwd-img-hmsi gwd-gen-1mb8gwdanimation" data-gwd-grp-id="O2_3" />
              <img alt={"gopomoLetter"} src={O_grey_Icon} className="gwd-grp-pkem gwd-img-xl7s gwd-img-gnbr gwd-gen-c0nagwdanimation" data-gwd-grp-id="OG2_5" />
              <img alt={"gopomoLetter"} src={O_grey_Icon} className="gwd-grp-pkem gwd-img-xl7s gwd-img-8gvz gwd-img-me5j gwd-gen-j19pgwdanimation" data-gwd-grp-id="OG2_6" />
              <img alt={"gopomoLetter"} src={O_yellow_Icon} className="gwd-img-1y5d gwd-img-rmjh gwd-grp-pkem gwd-img-ob5b gwd-img-1594 gwd-img-1fu1 gwd-gen-ijf5gwdanimation gwd-img-5wfj" data-gwd-grp-id="OY2_2" />
              <img alt={"gopomoLetter"} src={O_white_Icon} className="gwd-grp-pkem gwd-img-xl7s gwd-img-psai gwd-img-ctnr gwd-img-i1hf gwd-gen-111jgwdanimation gwd-img-1io8" data-gwd-grp-id="OW2_2" />
            </div>
            <div className="gwd-grp-pkem gwd-div-jap2 gwd-div-htvy gwd-div-lw4r gwd-div-1tkw gwd-div-wmi7" data-gwd-grp-id="divO2">
              <img alt={"gopomoLetter"} src={O_Icon} className="gwd-img-atmk gwd-img-hmsi gwd-grp-pkem gwd-gen-kiwygwdanimation" data-gwd-grp-id="O2_4" />
              <img alt={"gopomoLetter"} src={O_grey_Icon} className="gwd-img-xl7s gwd-img-gnbr gwd-grp-pkem gwd-gen-ypthgwdanimation" data-gwd-grp-id="OG2_7" />
              <img alt={"gopomoLetter"} src={O_grey_Icon} className="gwd-img-xl7s gwd-img-8gvz gwd-img-me5j gwd-grp-pkem gwd-gen-x9wvgwdanimation" data-gwd-grp-id="OG2_8" />
              <img alt={"gopomoLetter"} src={O_yellow_Icon} className="gwd-img-1y5d gwd-img-rmjh gwd-img-ob5b gwd-img-1594 gwd-img-1fu1 gwd-grp-pkem gwd-gen-13gagwdanimation gwd-img-7h75" data-gwd-grp-id="OY2_3" />
              <img alt={"gopomoLetter"} src={O_white_Icon} className="gwd-img-xl7s gwd-img-psai gwd-img-ctnr gwd-img-i1hf gwd-grp-pkem gwd-gen-151bgwdanimation gwd-img-ecoc" data-gwd-grp-id="OW2_3" />
            </div>
            <div className="gwd-div-jap2 gwd-grp-pkem gwd-div-6uxd" data-gwd-grp-id="divO3">
              <img alt={"gopomoLetter"} src={O_Icon} className="gwd-img-atmk gwd-img-hmsi gwd-grp-pkem gwd-gen-qycugwdanimation" data-gwd-grp-id="O2_5" />
              <img alt={"gopomoLetter"} src={O_grey_Icon} className="gwd-img-xl7s gwd-img-gnbr gwd-grp-pkem gwd-gen-1kaqgwdanimation" data-gwd-grp-id="OG2_9" />
              <img alt={"gopomoLetter"} src={O_grey_Icon} className="gwd-img-xl7s gwd-img-8gvz gwd-img-me5j gwd-grp-pkem gwd-gen-1lfmgwdanimation" data-gwd-grp-id="OG2_10" />
              <img alt={"gopomoLetter"} src={O_yellow_Icon} className="gwd-img-1y5d gwd-img-rmjh gwd-img-ob5b gwd-img-1594 gwd-img-1fu1 gwd-grp-pkem gwd-gen-1npbgwdanimation gwd-img-16rw" data-gwd-grp-id="OY2_4" />
              <img alt={"gopomoLetter"} src={O_white_Icon} className="gwd-img-xl7s gwd-img-psai gwd-img-ctnr gwd-img-i1hf gwd-grp-pkem gwd-gen-1mhkgwdanimation gwd-img-18tt" data-gwd-grp-id="OW2_4" />
            </div>
            <div className="gwd-div-pvpj gwd-grp-pkem" data-gwd-grp-id="divM">
              <img alt={"gopomoLetter"} src={M_Icon} className="gwd-grp-pkem gwd-img-vp03" data-gwd-grp-id="M_2" />
              <img alt={"gopomoLetter"} src={m2Icon} className="gwd-grp-pkem gwd-img-10xi gwd-gen-140mgwdanimation" data-gwd-grp-id="m2" />
              <img alt={"gopomoLetter"} src={m3Icon} className="gwd-grp-pkem gwd-img-1fol gwd-gen-66ybgwdanimation" data-gwd-grp-id="m3" />
              <img alt={"gopomoLetter"} src={m4Icon} className="gwd-grp-pkem gwd-img-eut0 gwd-gen-mw5wgwdanimation" data-gwd-grp-id="m4" />
              <img alt={"gopomoLetter"} src={m1Icon} className="gwd-img-d1y7 gwd-grp-pkem gwd-gen-jvj5gwdanimation" data-gwd-grp-id="m1" />
            </div>
            <img alt={"gopomoLetter"} src={slineIcon} className="gwd-grp-pkem gwd-img-13ds gwd-gen-13sdgwdanimation" data-gwd-grp-id="sline" />
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);

export default Landing;