import React from "react";
import { Container, Row, Col } from "reactstrap";
import _ from "lodash";

const HeroContainer = ({ title = "", splashQuote = "", subTitle = "" }) => {
  let h1SpanCounter = 0; /* for fix words wrap glitch, animation timing  */
  let h5SpanCounter = 0; /* for fix words wrap glitch, animation timing  */
  return (
    <Container fluid={true} className="heroContainer">
      <Container>
        <Row className="heroContent flex-column flex-sm-row-reverse justify-content-center align-items-start align-items-sm-center">
          <Col xs={{ offset: 1, size: 6 }} sm={{ offset: 0, size: "12" }} md={{ size: "3" }} lg={{ size: "4" }} xl={{ size: 3 }}>
            <div className="titleContainer mt-5X mb-autoX mx-autoX m-sm-autoX d-flex align-items-center justify-content-center">
              <span className="topLeft" />
              <h3 className="text-center m-auto">
                {title}
              </h3>
              <span className="bottomRight" />
            </div>
          </Col>
          <Col xs="12" sm={{ size: "12" }} md={{ size: "7" }} lg={{ size: "6" }} xl={{ size: 7 }} className="splashSubTitleContainer">
            <h1 className="letterTypingShow">
              {
                _.split(splashQuote, " ").map((word, wordIndex) => {
                  if (word !== "\n") {

                    /* maping for each character */
                    let charactersArr = _.split(word, "");
                    charactersArr = charactersArr.map((character, index) => {
                      h1SpanCounter++;
                      return (
                        <span key={wordIndex.toString() + index.toString()}
                          className={`ani ${"spanCount" + h1SpanCounter}`}>
                          {character}
                        </span>
                      )
                    });

                    /* add space for each word end */
                    h1SpanCounter++;
                    charactersArr.push(
                      <span key={wordIndex.toString()} className={`ani space ${"spanCount" + h1SpanCounter}`}>
                        &nbsp;
                      </span>
                    )
                    return (<div className="d-inline-block" key={wordIndex.toString()}>{charactersArr}</div>);
                  } else {
                    return (<br key={wordIndex.toString()} />)
                  }
                })
              }
            </h1>
            <h5 className="letterTypingShow my-5">
              {
                _.split(subTitle, " ").map((word, wordIndex) => {
                  if (word !== "\n") {
                    let charactersArr = _.split(word, "");

                    /* maping for each character */
                    charactersArr = charactersArr.map((character, index) => {
                      h5SpanCounter++;
                      return (
                        <span key={wordIndex.toString() + index.toString()}
                          className={`ani ${"spanCount" + h5SpanCounter}`}>
                          {character}
                        </span>
                      )
                    });

                    /* add space for each word end */
                    h5SpanCounter++;
                    charactersArr.push(
                      <span key={wordIndex.toString()} className={`ani ${"spanCount" + h5SpanCounter}`}>
                        &nbsp;
                      </span>
                    );
                    return (<div className="d-inline-block" key={wordIndex.toString()}>{charactersArr}</div>);
                  } else {
                    return (<br key={wordIndex.toString()} />)
                  }
                })
              }
            </h5>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default HeroContainer;