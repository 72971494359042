import React from "react";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import metaImage from "../../images/portfolio/meta.jpg";
import { Container, CardDeck } from "reactstrap";
import i18next from '../../i18next';
import HeroContainer from "../common/HeroContainer";
import PortfolioSection from "./PortfolioSection";

import mammographyThumbnail from "../../images/portfolioMammography/thumbnail.jpg";
import ttapThumbnail from "../../images/portfolioTtap/thumbnail.jpg";
import moonlightdogThumbnail from "../../images/portfolioMoonlightDog/thumbnail.jpg";
import matchfitThumbnail from "../../images/portfolioMatchfit/thumbnail.jpg";
import lunicaThumbnail from "../../images/portfolioLunica/thumbnail.jpg";
import compalThumbnail from "../../images/portfolioCompal/thumbnail.jpg";
import minefillServicesThumbnail from "../../images/portfolioMinefillServices/thumbnail.jpg";
import moonspecsThumbnail from "../../images/portfolioMoonspecs/thumbnail.jpg";
import heroSpoonThumbnail from "../../images/portfolioHeroSpoon/thumbnail.jpg";
import iRetailARThumbnail from "../../images/portfolioiRetailAR/thumbnail.jpg";

export default class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /* inital scoll settting to be on the top */
    window.scrollTo(0, 0);
  }

  render() {
    const url = new URL(window.location.href);
    const helmetTitle = `${i18next.t("portfolio.title")} - ${i18next.t("jsonLdOrganization.shortCompName")}`;
    const helmetDesContent = `${i18next.t("portfolio.metaDes")}`;

    return (
      <div className="portfolio">
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetDesContent} />

          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:url" content={`${url.href}`} />
          <meta property="og:description" content={helmetDesContent} />

          <meta property="og:image" content={metaImage} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="300" />
          <meta property="og:image:alt" content={helmetTitle} />

          {/* canonical url, see: https://support.google.com/webmasters/answer/139066 */}
          <link rel="canonical" href={`${url.href}`} />
        </Helmet>
        <HeroContainer
          title={i18next.t("portfolio.title")}
          splashQuote={i18next.t("portfolio.splashQuote")}
          subTitle={i18next.t("portfolio.subTitle")}
        />
        <Container fluid={true} className="mainSections">
          <Container className="py-2 py-md-3 py-lg-4 py-xl-5">
            <CardDeck className="justify-content-around justify-content-lg-start">
              <PortfolioSection
                imageUrl={mammographyThumbnail}
                title={i18next.t("mammographyPortfolio.abbriTitle")}
                linkTo="mammography"
                description={<p>{i18next.t("portfolio.card.mammography")}</p>}
              />
              <PortfolioSection
                imageUrl={ttapThumbnail}
                title={i18next.t("ttapPortfolio.abbriTitle")}
                linkTo="ttap"
                description={<p>{i18next.t("portfolio.card.ttap")}</p>}
                reverse={true}
              />
              <PortfolioSection
                imageUrl={moonlightdogThumbnail}
                title={i18next.t("MoonlightdogPortfolio.abbriTitle")}
                linkTo="moonlight-dog-cafe"
                description={<p>{i18next.t("portfolio.card.moonlightDogCafe")}</p>}
              />
              <PortfolioSection
                imageUrl={matchfitThumbnail}
                title="MatchFit"
                description={<p>{i18next.t("portfolio.card.matchfit")}</p>}
                linkTo={"matchfit"}
                reverse={true}
              />
              <PortfolioSection
                imageUrl={lunicaThumbnail}
                title={i18next.t("LunicaPortfolio.abbriTitle")}
                linkTo={"lunica"}
                description={<p>{i18next.t("portfolio.card.lunica")}</p>}
              />
              <PortfolioSection
                imageUrl={compalThumbnail}
                title={i18next.t("CompalPortfolio.abbriTitle")}
                linkTo={"compal"}
                description={<p>{i18next.t("portfolio.card.compal")}</p>}
                reverse={true}
              />
              <PortfolioSection
                imageUrl={minefillServicesThumbnail}
                title={i18next.t("MinefillServicesPortfolio.abbriTitle")}
                linkTo={"minefill-service"}
                description={<p>{i18next.t("portfolio.card.minefillServices")}</p>}
              />
              <PortfolioSection
                imageUrl={moonspecsThumbnail}
                title={i18next.t("MoonspecsPortfolio.abbriTitle")}
                linkTo={"moonspecs"}
                description={<p>{i18next.t("portfolio.card.moonspecs")}</p>}
                reverse={true}
              />
              <PortfolioSection
                imageUrl={heroSpoonThumbnail}
                title={i18next.t("HeroSpoonPortfolio.abbriTitle")}
                linkTo={"herospoon"}
                description={<p>{i18next.t("portfolio.card.heroSpoon")}</p>}
              />
              <PortfolioSection
                imageUrl={iRetailARThumbnail}
                title={i18next.t("iRetailARPortfolio.abbriTitle")}
                linkTo={"iRetailAR"}
                description={<p>{i18next.t("portfolio.card.iRetailAR")}</p>}
                reverse={true}
              />
            </CardDeck>
          </Container>
        </Container>
      </div>
    )
  }
}