import React from 'react';
import { Redirect } from 'react-router';

import i18n from './i18next';


const LocaleDetector = () => {
  return <Redirect to={`/${i18n.language}/`} />;
}

export default LocaleDetector;
