import React from "react";
import i18next from "../i18next";
import _ from "lodash";
import { withRouter } from "react-router";


class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { routersArr: [""] };
  }

  componentDidMount() {
    /* set navigation for first mount */
    this.setRoutesStatus(this.props.location.pathname);
  }

  componentWillReceiveProps(nextProps) {
    /* set navgation state by `withRouter` */
    this.setRoutesStatus(nextProps.location.pathname);
  }

  setRoutesStatus = (pathname) => {
    if (pathname.charAt(0) === "/") {
      const pathsArr = _.split(pathname.substr(1), "/");
      this.setState({ routersArr: pathsArr });
    }
  }

  render() {
    return (
      <div className="footer">
        {
          (this.state.routersArr[0] !== "") ?
            <div className="footerContainer align-items-center d-flex justify-content-center text-white">
              <p className="m-auto">
                &copy; Copyright {new Date().getFullYear()}, {i18next.t("footer.companyName")}
              </p>
            </div>
            :
            null
        }
      </div>
    )
  }
}


export default withRouter(Footer);