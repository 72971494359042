import React from "react";
import { Col, ListGroup, ListGroupItem } from "reactstrap";
import _ from "lodash";

const ConceptKeyPointsListSection = ({ keyPoints = [] }) => (
  <Col className="conceptKeyPointsListSection" xs="12" sm="12" md="10" >
    <ListGroup className="pl-5 px-xl-0">
      {keyPoints.map((pointItem, index) => (
        <ListGroupItem key={index.toString()}>
          <h5>{pointItem}</h5>
        </ListGroupItem>
      ))}
    </ListGroup>
  </Col>
)

class ConceptDetailSection extends React.Component {
  static defaultProps = {
    title: "",
    description: ""
  }

  constructor(props) {
    super(props);
    this.state = { isShowTitle: false };
  }

  /* reference for portfolio title */
  titleRef = React.createRef(); /* the portfolio name */

  componentWillReceiveProps() {
    this.showTitle();
  }

  showTitle = () => {
    const titleRefContainer = this.titleRef.current;
    if (titleRefContainer) {
      if (window.innerHeight > titleRefContainer.getBoundingClientRect().top + titleRefContainer.getBoundingClientRect().height) {
        /* titleRefContainer's bottom relative to top is lower then view-port */
        this.setState({ isShowTitle: true });
      }
    }
  }

  render() {
    return (
      <Col className="designConceptSection" xs="12" sm="12" md="10" >
        <h2 ref={this.titleRef}
          className={`${(this.state.isShowTitle) ? "transiformScale" : ""}`}>
          {_.split(this.props.title, "").map((character, index) =>
            (<span key={index.toString()}>{character}</span>)
          )}</h2>
        <p className="pt-md-1 pt-lg-2">{this.props.description}</p>
      </Col>
    )
  }
}

export { ConceptDetailSection, ConceptKeyPointsListSection };