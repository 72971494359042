import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';

// resources
import enResource from './locales/en/app.json';
import zhHantResource from './locales/zh-hant/app.json';

i18n
  .use(LngDetector)
  .init({
    load: 'currentOnly',
    ns: ['app'],
    defaultNS: 'app',
    lowerCaseLng: true,
    whitelist: ['zh', 'zh-hant', 'en'],
    fallbackLng: {
      'default': ['zh', 'zh-hant', 'en']
    },
    initImmediate: false,
    debug: false,
    resources: {
      en: { app: enResource },
      'zh-hant': { app: zhHantResource },
    },
  });

export default i18n;
