import React from "react";
import { Row, Col } from "reactstrap";

export default class JobOpportunitySection extends React.PureComponent {
  static defaultProps = {
    title: "",
    description: "",
    windowScrollY: 0
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  /* reference for content */
  contentRef = React.createRef();

  componentDidUpdate() {
    this.contentAnimation();
  }

  contentAnimation = () => {
    const contentCurrent = this.contentRef.current;
    /* getBoundingClientRect(), return value `relative to the top-left of the viewport.`ref:https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect */
    if (contentCurrent) {
      if (window.innerHeight - contentCurrent.getBoundingClientRect().height / 2 > contentCurrent.getBoundingClientRect().top) {
        contentCurrent.classList.add("showContent");
      } else {
      }
    }
  }


  render() {
    return (
      <div ref={this.contentRef} className={`jobOpportunitySection`}>
        <Row className="py-sm-1 py-md-2 py-lg-3 py-xl-4 mt-sm-2 mt-md-4 mt-xl-5">
          <Col xs="12">
            <div className="textDecorationAnchor mb-3 mx-auto mb-sm-4 mb-lg-5">
              <h2 className="text-center"><b>{this.props.title}</b></h2>
              <div className="textDecoration" />
            </div>
          </Col>
          <Col className="m-auto" xs="12" sm="10" lg="9" xl="7">
            {this.props.description}
          </Col>
        </Row>
      </div>
    )
  }
}