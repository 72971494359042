import React from "react";
import i18next from "../../i18next";
import "url-polyfill"; /* https://www.npmjs.com/package/url-polyfill */
import { Helmet } from "react-helmet"; /* https://github.com/nfl/react-helmet"; */
import metaImage from "../../images/job/meta.jpg";
import { Container } from "reactstrap";

/* children components */
import HeroContainer from "../common/HeroContainer";
import ToggleSection from "./ToggleSection";
import JobOpportunitySection from "./JobOpportunitySection";
import ConactForm from "./ContactForm";

export default class Job extends React.Component {
  constructor(props) {
    super(props);
    this.state = { windowScrollY: 0 };
  }

  /* mounted status, to determine whether to setState */
  mounted = false;

  componentDidMount() {
    this.mounted = true;
    /* inital scoll settting to be on the top */
    window.scrollTo(0, 0);
    window.onscroll = () => {
      if (this.mounted) {
        /* need to set scroll in parent prevent glitch */
        this.setState({ windowScrollY: window.scrollY });
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onScrollToForm = () => {
    document.querySelector('#jobForm').scrollIntoView({
      behavior: 'smooth'
    });
  }

  render() {
    const url = new URL(window.location.href);
    const helmetTitle = `${i18next.t("job.title")} - ${i18next.t("jsonLdOrganization.shortCompName")}`;
    const helmetDesContent = `${i18next.t("job.metaDes")}`;

    const jsonLdDesignerJob = {
      "@context": "http://schema.org/",
      "@type": "JobPosting",
      "title": "Designer",
      "description": i18next.t("job.designerDescription.paragraph1") + i18next.t("job.designerDescription.paragraph2") + i18next.t("job.designerDescription.paragraph3"),
      "hiringOrganization": {
        "@type": "Organization",
        "name": "Gopomo Inc.",
        "url": url.href,
        "logo": url.origin + "/GopomoLogo.png"
      },
      "datePosted": new Date(),
      "jobLocation": [{
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "TW",
          "addressLocality": "台中市",
          "addressRegion": "北屯區",
          "postalCode": "406",
          "streetAddress": "平德路76號",
        },
        "additionalProperty": {
          "@type": "PropertyValue",
          "value": "TELECOMMUTE"
        }
      },
      {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "CA",
          "addressLocality": "Mississauga",
          "addressRegion": "ON",
          "postalCode": "L5B4M2",
          "streetAddress": "33 Elm Drive West, Suite 2508",
        },
        "additionalProperty": {
          "@type": "PropertyValue",
          "value": "TELECOMMUTE"
        }
      }]
    }

    const jsonLdDeveloperJob = {
      "@context": "http://schema.org/",
      "@type": "JobPosting",
      "title": "Developer",
      "description": i18next.t("job.networkDescription.paragraph1") + i18next.t("job.networkDescription.paragraph2"),
      "hiringOrganization": {
        "@type": "Organization",
        "name": "Gopomo Inc.",
        "url": url.href,
        "logo": url.origin + "/GopomoLogo.png"
      },
      "datePosted": new Date(),
      "jobLocation": [{
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "TW",
          "addressLocality": "台中市",
          "addressRegion": "北屯區",
          "postalCode": "406",
          "streetAddress": "平德路76號",
        },
        "additionalProperty": {
          "@type": "PropertyValue",
          "value": "TELECOMMUTE"
        }
      },
      {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "CA",
          "addressLocality": "Mississauga",
          "addressRegion": "ON",
          "postalCode": "L5B4M2",
          "streetAddress": "33 Elm Drive West, Suite 2508",
        },
        "additionalProperty": {
          "@type": "PropertyValue",
          "value": "TELECOMMUTE"
        }
      }]
    }

    return (
      <div className="job">
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetDesContent} />

          {/* Open Graph Protocal (Facebook), see: http://ogp.me/ */}
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:url" content={`${url.href}`} />
          <meta property="og:description" content={helmetDesContent} />

          <meta property="og:image" content={metaImage} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="300" />
          <meta property="og:image:alt" content={`Job - GOPOMO Inc.\n${i18next.t("job.ForDesigners")}, ${i18next.t("job.ForDevelopers")}, ${i18next.t("job.ForNetwork")}`} />

          <script type="application/ld+json">
            {`${JSON.stringify(jsonLdDesignerJob)}`}
          </script>
          <script type="application/ld+json">
            {`${JSON.stringify(jsonLdDeveloperJob)}`}
          </script>

          {/* canonical url, see: https://support.google.com/webmasters/answer/139066 */}
          <link rel="canonical" href={`${url.href}`} />
        </Helmet>
        <HeroContainer
          title={i18next.t("job.title")}
          splashQuote={i18next.t("job.splashQuote")}
          subTitle={i18next.t("job.subTitle")}
        />
        <div className="mainSections">
          <Container fluid={true}>
            <ToggleSection onScrollToForm={() => this.onScrollToForm()} />
            <JobOpportunitySection
              windowScrollY={this.state.windowScrollY}
              title={i18next.t("job.ForDesigners")}
              description={
                <div><b>
                  <p>{i18next.t("job.designerDescription.paragraph1")}</p>
                  <p>{i18next.t("job.designerDescription.paragraph2")}</p>
                  <p>{i18next.t("job.designerDescription.paragraph3")}
                    <button className="btnLinkBaseStyle" onClick={() => this.onScrollToForm()}>{i18next.t("job.designerDescription.chatLinkText")}</button>
                  </p>
                </b></div>
              }
            />
            <JobOpportunitySection
              title={i18next.t("job.ForDevelopers")}
              description={
                <div><b>
                  <p>{i18next.t("job.developerDescription.paragraph1")}</p>
                  <p>{i18next.t("job.developerDescription.paragraph2")}</p>
                  <p>{i18next.t("job.developerDescription.paragraph3")}</p>
                  <p>
                    <button className="btnLinkBaseStyle" onClick={() => this.onScrollToForm()}>{i18next.t("job.developerDescription.workingLinkText")}</button>
                  </p>
                </b></div>
              }
            />
            <JobOpportunitySection
              title={i18next.t("job.ForNetwork")}
              description={
                <div><b>
                  <p>{i18next.t("job.networkDescription.paragraph1")}</p>
                  <p>{i18next.t("job.networkDescription.paragraph2")}</p>
                  <p>{i18next.t("job.networkDescription.paragraph3prepend")}
                    <button className="btnLinkBaseStyle" onClick={() => this.onScrollToForm()}>
                      &nbsp;{i18next.t("job.networkDescription.form")}&nbsp;
                    </button>
                    {i18next.t("job.networkDescription.or")}
                    &nbsp;<a href={"https://www.facebook.com/GopomoInc/"} target={"_blank"}>Facebook.</a>&nbsp;
                    {i18next.t("job.networkDescription.paragraph3append")}
                  </p>
                </b></div>
              }
            />
          </Container>
          <Container>
            <ConactForm />
          </Container>
        </div>
      </div>
    )
  }
}